<template>
  <div id="main-my-pass-codes" v-bind:class="getStyle()">
    <b-row align-h="center" class="" style="width: 100%; margin: 0px">
      <b-col class="" style="width: 100%; padding: 0px" v-bind:class="getRowStyle()">
        <b-row align-h="between" style="margin: 0px">
          <b-col align-self="start" cols="auto" sm="6" md="6" lg="6" class="p-1 pr-3">
            <b-form-input debounce="300" @update="this.filter_updated_event"
                          id="filter-input"
                          v-model="filter_room_name_local"
                          type="search"
                          placeholder="Поиск по имени комнаты"
                          size="sm"
            ></b-form-input>

          </b-col>
          <b-col cols="auto" class="p-1 pr-0" align-self="end">
            <!--        // Создать новый пропуск-->
            <b-button v-if="$root.global_object.is_auth()" variant="primary" size="sm"
                      @click="()=>{this.$root.global_object.showModalCreatePassCode(myUpdateEvent)}"
                      v-b-popover.hover.left="'Создать пропуск'">
              <b-icon icon="plus" aria-hidden="true"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="center" class="justify-content-md-center" style="width: 100%; margin: 0px">
      <b-col class="" style="width: 100%; padding: 0px" v-bind:class="getRowStyle()">

        <b-overlay :show="flag_loading" rounded="sm">
          <span v-if="info_is_loaded">
            <div class="my-list-item" v-for="(item, index) in pass_codes" :key="index+'2'">
              <b-row align-h="between" style="text-align: left">
                <b-col cols="12">
                  <PassCodeEditor v-bind:pass_code_json="item"/>
                </b-col>
              </b-row>
            </div>
          </span>
          <p v-else style="padding-top: 1rem">{{ data_message }}</p>
        </b-overlay>
        <b-row align-h="between">
          <b-col cols="auto">
        <b-pagination-nav
            v-if="!this.modal"
            v-model="currentPage"
            :number-of-pages="number_of_pages"
            :base-url='get_url_for_pagination()'
            use-router
            size="sm"
        ></b-pagination-nav>
        <b-pagination
            v-if="this.modal"
            v-model="currentPage_mobile"
            :per-page="this.per_page"
            :total-rows="this.total_rows"
            size="sm"
        ></b-pagination>
          </b-col>
          <b-col cols="auto">
            Всего найдено: {{ this.total_rows }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PassCodeEditor from "./PassCodeEditor";

export default {
  components: {PassCodeEditor},
  props: {
    page: {
      type: String,
      default: "1"
    },
    filter_room_name: {
      type: String,
      default: ''
    },
    modal: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    currentPage: function (newVal) {
      this.api_get_own_pass_codes("", this.ts, newVal - 1);
    },
    page: function (newVal) {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.currentPage = newVal;
      this.api_get_own_pass_codes("", this.ts, newVal - 1);

    },
    filter_room_name: function (newVal_filter) {
      this.filter_room_name_local = newVal_filter;
      this.api_get_own_pass_codes("", this.ts, 0);
    },
    currentPage_mobile: function (newPage) {
      this.api_get_own_pass_codes("", this.ts, newPage - 1);
    }
  },
  name: "PassCodes",
  data() {
    return {
      currentPage: 1,
      currentPage_mobile: 1,
      total_rows: 0,
      per_page: 10,
      number_of_pages: 1,
      filter_room_name_local: '',
      data_message: "",
      ts: null,
      pass_codes: [{room_name: "test"}, {room_name: "test2"}],
      messages: [],
      flag_loading: true,
      info_is_loaded: false,
      fields_history: [{
        key: 'actions',
        label: "",
        sortable: false
      },
        {
          key: 'room_name',
          label: "Имя комнаты",
          sortable: true
        },
        {
          key: 'create_ts',
          label: "Время входа",
          sortable: true
        },
        {
          key: 'duration',
          label: "Длительность",
          sortable: true
        }
      ],
    }
  },
  mounted() {
    //this.$root.global_object.api_check_auth(null, null);
    if (!this.ts) {
      this.ts = Date.now() / 1000;
    }
    let page = this.$route.query.page;
    if (!page) {
      page = 1;
    }
    this.currentPage = parseInt(page);
    let c_window = this;

    // this.$router.history.listen(function (newLocation) {
    //   //console.log(newLocation);
    //   let page = newLocation.query.page;
    //   if (!page) {
    //     page = 1;
    //   }
    //   //  alert(newLocation)
    //   c_window.currentPage = page;
    //   //c_window.api_get_rooms_history("", c_window.ts, page - 1);
    //
    // });
    this.filter_room_name_local = this.filter_room_name;
    this.api_get_own_pass_codes("", c_window.ts, page - 1);

  },
  methods: {
    get_url_for_pagination() {
      let url = "?";
      if (this.filter_room_name_local && this.filter_room_name_local.length > 0)
        url += "filter_room_name=" + this.filter_room_name_local + "&";
      url += 'page=';
      return url;
    },
    filter_updated_event() {
      this.currentPage = 1;
      this.currentPage_mobile = 1;
      if (this.modal)
        this.api_get_own_pass_codes("", this.ts, 0);
      else if (this.filter_room_name_local.length > 0)
        this.$router.push({name: 'pass_codes', query: {filter_room_name: this.filter_room_name_local}}).catch(() => {
        });
      else
        this.$router.push({name: 'pass_codes'})
      //
    },
    myUpdateEvent() {
      //alert("My update event");
      this.api_get_own_pass_codes("", this.ts, this.currentPage - 1);

    },
    get_duration_string(start_ts, end_ts) {
      if (end_ts === "" || end_ts == null) {
        // alert("NULL");
        return "online";
      }
      let start_date = new Date(Math.trunc(start_ts * 1000));
      let end_date = new Date(Math.trunc(end_ts * 1000));
      let dur_sec = Math.trunc((end_date - start_date) / 1000);
      let seconds = dur_sec % 60; // Получаем секунды
      let minutes = dur_sec / 60 % 60; // Получаем минуты
      let hour = dur_sec / 60 / 60 % 60; // Получаем часы
      // Создаём строку с выводом времени
      return `${Math.trunc(hour)}:${Math.trunc(minutes).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}:${Math.trunc(seconds).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}`;

    },
    get_end_ts_string(ts) {
      // alert(ts.value)
      if (ts.value === "" || ts.value == null) {
        // alert("NULL");
        return ""
      }

      return this.dateFormat(new Date(Math.trunc(ts.value * 1000)), "%d.%m.%Y %H:%M:%S", false)
    },
    api_get_own_pass_codes(room_name, ts, page, limit = 10) {
      this.flag_loading = true;
      this.info_is_loaded = false;
      this.per_page = limit;
      this.axios

          .get('/api/v1/get_own_pass_codes?room_name=' + this.filter_room_name_local + '&ts=' + ts + '&page=' + page + '&limit=' + limit, {
            withCredentials: true
          })
          .then(response => {
            //"suc room info");
            //alert(JSON.stringify(response.data));
            // console.log(response.data)
            if (response.data) {

              //this.info_is_loaded = true;
              this.flag_loading = false;

              this.pass_codes = response.data.pass_codes;
              //alert(response.data.all_codes_count);
              //alert(Math.ceil(response.data.all_codes_count / limit));
              this.number_of_pages = Math.ceil((response.data.all_codes_count + 1) / limit);
              this.total_rows = response.data.all_codes_count;
              if (this.total_rows > 0)
                this.info_is_loaded = true;
              else
                this.data_message = "Данные отсутствуют";

            } else {
              this.info_is_loaded = false;
              this.data_message = "Данные отсутствуют";
              this.flag_loading = false;
            }
          })
          .catch((error) => {
            this.info_is_loaded = false;
            this.data_message = "Данные отсутствуют";
            this.flag_loading = false;
            if (error.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
          });
      //this.flag_loading = false;
    },
    isMobile() {
      let value = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i);
      return value != null;

    },
    getRowStyle() {
      if (this.modal)
        return "";
      else
        return "col-md-8 col-xl-8 col-sm-8";
    },
    getStyle() {
      if (this.modal)
        return "modal_style";
      else
        return "not_modal_style";
    },
    dateFormat: function (date, fstr, utc) {
      if (date === null)
        return ""
      utc = utc ? 'getUTC' : 'get';
      return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
          case '%Y':
            return date[utc + 'FullYear'](); // no leading zeros required
          case '%m':
            m = 1 + date[utc + 'Month']();
            break;
          case '%d':
            m = date[utc + 'Date']();
            break;
          case '%H':
            m = date[utc + 'Hours']();
            break;
          case '%M':
            m = date[utc + 'Minutes']();
            break;
          case '%S':
            m = date[utc + 'Seconds']();
            break;
          default:
            return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
      });
    },
  }
}
</script>
<style>
.page-item.active .page-link {
  background-color: #004085 !important;
  border-color: #004085;
}

.page-link {
  background-color: #eeeff4 !important;
  /*color: #004085;*/
}

.page-link:hover {
  background-color: #b8daff !important;
}
</style>
<style scoped>
.modal_style {
  padding: 0rem;
}

.not_modal_style {
  padding: 1rem;
  padding-top: 0rem;
}

.my-list-item {
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.125);
  border-top-width: 1px;
}
</style>