<template>
  <div id="main-monitoring" style="padding:1rem; padding-top: 0;">
    <b-row align-h="between" class="justify-content-md-center" style="width: 100%; margin: 0">
      <b-col class="col-md-8 col-xl-8" style="width: 100%; padding: 0">
        <b-row style="text-align: left" align-h="end" class="m-0">
          <b-col cols="auto" class="p-1">
            <b-dropdown variant="danger" size="sm" id="dropdown-options" text="Дополнительные операции" class="">
              <b-dropdown-item-button href="#" @click="api_put_clean_frozen_rooms" v-b-popover.hover.auto="'Вызывать, только если вы уверены, ' +
                       'что нет активных пользователей! Желательно вызывать после перезагрузки сервиса Prosody.'">Чистка
                застывших комнат
              </b-dropdown-item-button>
              <b-dropdown-item-button href="#" @click="api_put_fix_frozen_users">Чистка времени сессий пользователей
              </b-dropdown-item-button>
              <b-dropdown-item-button href="#" @click="api_put_recreate_personal_users_stats" >Пересчет статистики
                пользователей
              </b-dropdown-item-button>

            </b-dropdown>
          </b-col>
          <b-col cols="auto" class="p-1">
            <b-button size="sm" variant="primary" @click="update_data">
              <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
              Обновить
            </b-button>
          </b-col>
        </b-row>
        <b-row style="text-align: left" class="m-0">
          <b-col class="p-1">
            <b-card sub-title="Комнат online:">
              <h2>{{ rooms_online }}</h2>
              <b-link class="card-link"
                      :to="{name:'admin_rooms_history', query: {by_room: 'true', only_online: 'true'}}">
                Комнаты online
              </b-link>
            </b-card>
          </b-col>
          <b-col class="p-1">
            <b-card sub-title="Пользователей online:">
              <h2>{{ users_online }}</h2>
              <b-link class="card-link"
                      :to="{name:'admin_monitoring_users'}">
                Пользователи
              </b-link>
            </b-card>
          </b-col>
        </b-row>
        <b-row style="text-align: left" class="m-0">
          <b-col class="p-1">
            <b-card
                sub-title="Статистика по комнатам:">
              <b-tabs card style="!important;background: white">
                <b-tab title="По дням" active>
                  <b-card-text>
                    <b-row>
                      <b-col cols="6" class="p-0 m-0">
                        <b-row class="align-items-baseline p-0 m-0 justify-content-md-center">
                          <b-col cols="1" class="p-0 m-0" style="text-align: end">
                            <label size="sm" label-for="id_start_date"
                                   style="font-size: 0.8rem; vertical-align: center"><strong>C</strong></label>
                          </b-col>
                          <b-col cols="11" class="pl-1 pr-0">
                            <b-form-input size="sm" v-model="filter_rooms_start_ts_str" placeholder=""
                                          id="id_start_date"
                                          type="date" debounce="500" @update="update_graph_rooms"></b-form-input>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="6" class="p-0 m-0">
                        <b-row class="align-items-baseline p-0 m-0 justify-content-md-center">
                          <b-col cols="1" class="pr-0 pl-0 m-0" style="text-align: end">
                            <label size="sm" label-for="end_date"
                                   style="font-size: 0.8rem; vertical-align: center"><strong>По</strong></label>
                          </b-col>
                          <b-col cols="11" class="pr-0 pl-1 m-0">
                            <b-form-input size="sm" v-model="filter_rooms_end_ts_str" placeholder="" id="end_date"
                                          type="date" debounce="500" @update="update_graph_rooms"></b-form-input>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-col class="p-1">
                      <div class="14_conf">
                        <BarChart
                            :id="first"
                            :chartdata="chartDataConf14"
                            :options="chartOptionsConf14"
                            style="width: 100%; height: 14rem"
                        />
                      </div>
                    </b-col>
                  </b-card-text>
                </b-tab>
                <b-tab title="24 часа">
                  <b-card-text>
                    <b-col class="p-1">
                      <div class="24_conf">
                        <BarChart
                            :id="third"
                            :chartdata="chartDataConf24"
                            :options="chartOptionsConf24"
                            style="width: 100%; height: 14rem"
                        />
                      </div>
                    </b-col>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>

          <b-col class="p-1">
            <b-card
                sub-title="Статистика по пользователям:">
              <b-tabs card>
                <b-tab title="По дням">
                  <b-card-text>
                    <b-row>
                      <b-col cols="6" class="p-0 m-0">
                        <b-row class="align-items-baseline p-0 m-0">
                          <b-col cols="1" class="p-0 m-0" style="text-align: end">
                            <label size="sm" label-for="id_start_date"
                                   style="font-size: 0.8rem; vertical-align: center; horiz-align: right"><strong>C</strong></label>
                          </b-col>
                          <b-col cols="11" class="pl-1 pr-0">
                            <b-form-input size="sm" v-model="filter_users_start_ts_str" placeholder=""
                                          id="id_start_date"
                                          type="date" debounce="500" @update="update_graph_users"></b-form-input>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col cols="6" class="p-0 m-0">
                        <b-row class="align-items-baseline p-0 m-0">
                          <b-col cols="1" class="pr-0 pl-0 m-0" style="text-align: end">
                            <label size="sm" label-for="end_date_1"
                                   style="font-size: 0.8rem; vertical-align: center"><strong>По</strong></label>
                          </b-col>
                          <b-col cols="11" class="pr-0 pl-1 m-0">
                            <b-form-input size="sm" v-model="filter_users_end_ts_str" placeholder="" id="end_date_1"
                                          type="date" debounce="500" @update="update_graph_users"></b-form-input>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-col class="p-1">
                      <div class="14_custom">
                        <BarChart
                            :id="second"
                            :chartdata="chartDataUsers14"
                            :options="chartOptionsUsers14"
                            style="width: 100%; height: 14rem"
                        />
                      </div>
                    </b-col>
                  </b-card-text>
                </b-tab>
                <b-tab title="24 часа">
                  <b-card-text>
                    <b-col class="p-1">
                      <div class="24_custom">
                        <BarChart
                            :id="fours"
                            :chartdata="chartDataCustom24"
                            :options="chartOptionsUsers24"
                            style="width: 100%; height: 14rem"
                        />
                      </div>
                    </b-col>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//import moment from "moment";
import BarChart from "./BarChart";
//import { format } from 'date-fns/fp/formatWithOptions';

//import moment from "moment";

export default {
  name: "Monitoring-activity",
  components: {
    BarChart,
  },
  data() {
    return {
      mass14_conf: [],
      mass24_conf: [],
      mass14_users: [],
      mass24_users: [],
      filter_rooms_end_ts_num: Date.now() / 1000,
      filter_rooms_start_ts_num: null,
      filter_users_end_ts_num: Date.now() / 1000,
      filter_users_start_ts_num: null,
      rooms_online: 0,
      users_online: 0,

      first: 0,
      second: 1,
      third: 2,
      fours: 3,
      filter_rooms_start_ts_str: this.get_date(-14),
      filter_rooms_end_ts_str: this.get_date(0),
      filter_users_start_ts_str: this.get_date(-14),
      filter_users_end_ts_str: this.get_date(0),
      chartDataConf14: {
        labels: [],
        datasets: [
          {
            barPercentage: 0.6,
            //barThickness: 6,
            //maxBarThickness: 8,
            minBarLength: 2,
            label: 'Конференций',
            backgroundColor: '#0050cf',
            data: []
          }
        ]
      },
      chartOptionsConf14: {
        plugins: {
          datalabels: {
            // hide datalabels for all datasets
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },

        scales: {
          xAxes: [
            {
              type: 'time',
              offset: true,
              ticks: {
                autoSkip: true,
                //   maxTicksLimit: 10
              },
              time: {
                unit: 'day',
                tooltipFormat: 'YYYY-MM-DD HH:mm ZZ',
                displayFormats: {
                  second: 'HH:mm:ss',
                  minute: 'HH:mm',
                  hour: 'MMM D, HH[h]'
                },
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },

      chartDataConf24: {
        labels: [],
        datasets: [
          {
            barPercentage: 0.6,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: 'Конференций',
            backgroundColor: '#0050cf',
            data: []
          }
        ]
      },
      chartOptionsConf24: {
        plugins: {
          datalabels: {
            // hide datalabels for all datasets
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                //maxTicksLimit: 4
              },
              type: 'time',
              offset: true,
              time: {
                tooltipFormat: 'YYYY-MM-DD HH:mm ZZ',
                displayFormats: {
                  second: 'HH:mm:ss',
                  minute: 'HH:mm',
                  hour: 'MMM D, H[h]'
                },
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },

      chartDataUsers14: {
        labels: [],
        datasets: [
          {
            barPercentage: 0.6,
            //barThickness: 6,
            //maxBarThickness: 8,
            minBarLength: 2,
            label: 'Пользователей',
            backgroundColor: '#0050cf',
            data: []
          }
        ]
      },
      chartOptionsUsers14: {
        plugins: {
          datalabels: {
            // hide datalabels for all datasets
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              offset: true,
              // ticks: {
              //   autoSkip: true,
              //   minTicksLimit: 14
              // },
              time: {
                unit: 'day',
                tooltipFormat: 'YYYY-MM-DD HH:mm ZZ',
                displayFormats: {
                  second: 'HH:mm:ss',
                  minute: 'HH:mm',
                  hour: 'MMM D, H[h]'
                },
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },

      chartDataCustom24: {
        labels: [],
        datasets: [
          {
            barPercentage: 0.6,
            barThickness: 6,
            maxBarThickness: 8,
            minBarLength: 2,
            label: 'Пользователей',
            backgroundColor: '#0050cf',
            data: [],
            //pointRadius: 24,
          }
        ]
      },
      chartOptionsUsers24: {
        plugins: {
          datalabels: {
            // hide datalabels for all datasets
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              offset: true,
              ticks: {
                autoSkip: true,
              },
              time: {
                tooltipFormat: 'YYYY-MM-DD HH:mm ZZ',
                displayFormats: {
                  second: 'HH:mm:ss',
                  minute: 'HH:mm',
                  hour: 'MMM D, H[h]'
                },
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },

    }
  },

  mounted() {
    this.update_data()

  },

  methods: {
    update_data() {
      this.api_monitoring_get_users_online();
      this.api_monitoring_get_rooms_online()
      this.api_monitoring_get_online_stats_14_conf();
      this.api_monitoring_get_online_stats_24_conf();
      this.api_monitoring_get_online_stats_14_users();
      this.api_monitoring_get_online_stats_24_users();
    },
    makeToast(append = false, text, variant = null) {
      // this.toastCount++
      this.$bvToast.toast(text, {
        title: 'Уведомление',
        autoHideDelay: 2000,
        appendToast: append,
        variant: variant
      })
    },
    api_put_recreate_personal_users_stats() {
      this.axios
          .put('/api/v1/monitoring/put_recreate_personal_users_stats', "", {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then(response => {
                response.status
                this.makeToast(true, "Перестроение статистики завершено: " + response.status);
              }
          )
          .catch((error) => {
            this.makeToast(true, "Что-то пошло не так :(", "danger")
            if (error.response) {
               console.log(error.response.data);
               console.log(error.response.status);
               console.log(error.response.headers);
            }
          });
    },
    api_put_fix_frozen_users() {
      this.axios
          .put('/api/v1/monitoring/put_fix_frozen_users', "", {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then(response => {
                response.status
                this.makeToast(true, "Чистка завершена: " + response.status);
                this.makeToast(true, "Всего зачищено: " + response.data.number_of_fixes);
              }
          )
          .catch((error) => {
            this.makeToast(true, "Что-то пошло не так :(", "danger")
            if (error.response) {
               console.log(error.response.data);
               console.log(error.response.status);
               console.log(error.response.headers);
            }
          });
    },
    api_put_clean_frozen_rooms() {
      this.axios
          .put('/api/v1/monitoring/put_clean_frozen_rooms', "", {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then(response => {
                response.status
                this.makeToast(true, "Чистка завершена: " + response.status)
              }
          )
          .catch((error) => {
            this.makeToast(true, "Что-то пошло не так :(", "danger")
            if (error.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
          });
    },

    api_monitoring_get_users_online() {
      this.axios
          .get('/api/v1/monitoring/get_users_online', {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.users_online = response.data.users_online;
            }
          })
          .catch((error) => {
            if (error.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
          });
    },
    api_monitoring_get_rooms_online() {
      this.axios
          .get('/api/v1/monitoring/get_rooms_online', {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.rooms_online = response.data.rooms_online;
            }
          })
          .catch((error) => {
            if (error.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
          });
    },

    update_graph_rooms() {
      let c_window = this;
      if (c_window.filter_rooms_start_ts_str)
        this.filter_rooms_start_ts_num = Date.parse(c_window.filter_rooms_start_ts_str) / 1000;
      if (c_window.filter_rooms_end_ts_str)
        this.filter_rooms_end_ts_num = Date.parse(c_window.filter_rooms_end_ts_str) / 1000;
      this.api_monitoring_get_online_stats_14_conf();
    },

    update_graph_users() {
      let c_window = this;
      if (c_window.filter_users_start_ts_str)
        this.filter_users_start_ts_num = Date.parse(c_window.filter_users_start_ts_str) / 1000;
      if (c_window.filter_users_end_ts_str)
        this.filter_users_end_ts_num = Date.parse(c_window.filter_users_end_ts_str) / 1000;
      this.api_monitoring_get_online_stats_14_users();
    },

    get_marks_by_days(start_ts, end_ts) {
      if (start_ts == null || end_ts == null) {
        return 14;
      }
      let day_sec = end_ts - start_ts;
      let day = day_sec / 60 / 60 / 24;
      return Math.abs(day);
    },

    api_monitoring_get_online_stats_14_conf() {
      let s = Math.max(this.filter_rooms_start_ts_num, this.filter_rooms_end_ts_num)
      //alert(s)
      this.axios
          .get('/api/v1/monitoring/get_online_stats?type=days&marks=' + this.get_marks_by_days(this.filter_rooms_start_ts_num, this.filter_rooms_end_ts_num) + '&end_ts=' + s + '&event_code=101', {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.mass14_conf = response.data["label"];
              this.chartDataConf14 = {
                labels: [],
                datasets: [
                  {
                    barPercentage: 0.6,
                    //barThickness: 6,
                    //maxBarThickness: 8,
                    minBarLength: 2,
                    label: 'Конференций',
                    backgroundColor: '#0050cf',
                    data: []
                  }
                ]
              };

              for (let i = 0; i < this.mass14_conf.length; i++) {
                this.chartDataConf14.labels[i] = this.mass14_conf[i] * 1000;
              }
              this.chartDataConf14.datasets[0].data = response.data["value"];
            }
          })
          .catch((error) => {
            if (error.response) {
              //TODO добавить сообщение о ошибке загрузки
            }
          });
    },
    get_date(diff) {
      let date = new Date();
      date.setDate(date.getDate() + diff);
      return date.toISOString().substring(0, 10);
    },
    check_and_replace_current_day(/*day*/) {

    },
    api_monitoring_get_online_stats_24_conf() {
      this.axios
          .get('/api/v1/monitoring/get_online_stats?type=hours&marks=24&end_ts=' + Math.floor(Date.now() / 1000) + '&event_code=101', {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.mass24_conf = response.data["label"];
              this.chartDataConf24 = {
                labels: [],
                datasets: [
                  {
                    barPercentage: 0.6,
                    barThickness: 6,
                    maxBarThickness: 8,
                    minBarLength: 2,
                    label: 'Конференций',
                    backgroundColor: '#0050cf',
                    data: []
                  }
                ]
              };
              for (let i = 0; i < this.mass24_conf.length; i++) {
                this.chartDataConf24.labels[i] = this.mass24_conf[i] * 1000;
              }
              this.chartDataConf24.datasets[0].data = response.data["value"];
            }
          })
          .catch((error) => {
            if (error.response) {
              //TODO добавить сообщение о ошибке загрузки
            }
          });
    },

    api_monitoring_get_online_stats_14_users() {
      let s = Math.max(this.filter_users_start_ts_num, this.filter_users_end_ts_num)
      this.axios
          .get('/api/v1/monitoring/get_online_stats?type=days&marks=' + this.get_marks_by_days(this.filter_users_start_ts_num, this.filter_users_end_ts_num) + '&end_ts=' + s + '&event_code=201', {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.mass14_users = response.data["label"];
              this.chartDataUsers14 = {
                labels: [],
                datasets: [
                  {
                    barPercentage: 0.6,
                    //barThickness: 6,
                    //maxBarThickness: 8,
                    minBarLength: 2,
                    label: 'Пользователей',
                    backgroundColor: '#0050cf',
                    data: []
                  }
                ]
              };
              for (let i = 0; i < this.mass14_users.length; i++) {
                this.chartDataUsers14.labels[i] = this.mass14_users[i] * 1000;
              }

              this.chartDataUsers14.datasets[0].data = response.data["value"];
            }
          })
          .catch((error) => {
            if (error.response) {
              //TODO добавить сообщение о ошибке загрузки
            }
          });
    },

    api_monitoring_get_online_stats_24_users() {
      this.axios
          .get('/api/v1/monitoring/get_online_stats?type=hours&marks=24&end_ts=' + new Date() / 1000 + '&event_code=201', {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.mass24_users = response.data["label"];
              this.chartDataCustom24 = {
                labels: [],
                datasets: [
                  {
                    barPercentage: 0.6,
                    barThickness: 6,
                    maxBarThickness: 8,
                    minBarLength: 2,
                    label: 'Пользователей',
                    backgroundColor: '#0050cf',
                    data: [],
                    // pointRadius: 24,
                  }
                ]
              };

              for (let i = 0; i < this.mass24_users.length; i++) {
                this.chartDataCustom24.labels[i] = this.mass24_users[i] * 1000;
              }

              this.chartDataCustom24.datasets[0].data = response.data["value"];
            }
          })
          .catch((error) => {
            if (error.response) {
              //TODO добавить обработку ошибок
            }
          });
    },
  }
}
</script>

<style>
.card-header {
  background-color: white !important;
}
</style>
