<template>
  <div style="width: 100%; height: 100%; padding-top: 0rem; ">
    <div id="jitsi-container" class="my-container"
      style="width: 100%; height: 100%;  background: #e6e6e6; display: none; ">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div id="jitsi-text" class="my-container"
      style="text-align: center; vertical-align: middle; width: 100%; display: none; height: 100%;">
      <div id="list-item-1" style="display: table-cell; vertical-align: middle; font-size: 18px; display: table-cell;">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        Подключение...
      </div>
    </div>
    <!--    Модальное окно для гостевого входа-->
    <b-modal id="modal-guest-login" title="Гостевой вход" no-close-on-backdrop centered scrollable ok-only
      ok-title="Закрыть" size="md" footer-class="p-2">
      <template #modal-footer="{ cancel }">
        <!--        <b>Custom Footer</b>-->
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="md" variant="primary" type="submit"
          :disabled="!(validation_guest_room_name && validation_code && validation_guest_email && validation_guest_name)"
          @click="open_room_as_guest">
          Подключиться
        </b-button>
        <b-button size="md" variant="secondary" @click="cancel()">
          Отмена
        </b-button>
        <!-- Button with custom close trigger value -->
        <!--        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">-->
        <!--          Forget it-->
        <!--        </b-button>-->
      </template>
      <b-form @submit.stop.prevent>
        <b-form-group>
          <label label-for="guest_form_room_name">Имя комнаты:</label>
          <b-form-input placeholder="Введите имя комнаты" debounce="300" v-model="guest_form.room_name"
            :state="this.validation_guest_room_name" id="guest_form_room_name" required></b-form-input>
          <b-form-invalid-feedback :state="this.validation_guest_room_name">
            Длина имени от 2 до 60 символов. Допустимы только символы латинского алфавита [a-z,A-Z], цифры [0-9] и
            символы
            ["_","-"].
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label label-for="guest_form_code">Код:</label>
          <b-form-input placeholder="Введите код пропуска" debounce="300" v-model="guest_form.code"
            :state="this.validation_code" id="guest_form_code" required></b-form-input>
          <b-form-invalid-feedback :state="this.validation_code">
            Длина кода от 2 до 17 символов.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label label-for="guest_form_guest_name">Ваши ФИО:</label>
          <b-form-input placeholder="Введите ваши ФИО" debounce="300" v-model="guest_form.guest_name"
            :state="this.validation_guest_name" id="guest_form_guest_name" required></b-form-input>
          <b-form-invalid-feedback :state="this.validation_guest_name">
            Длина ФИО от 2 до 17 символов.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <label label-for="guest_form_guest_email">Email:</label>
          <b-form-input placeholder="Введите ваш Email" debounce="300" v-model="guest_form.guest_email" type="email"
            :state="this.validation_guest_email" id="guest_form_guest_email" required></b-form-input>
          <b-form-invalid-feedback :state="this.validation_guest_email">
            Введите существующий Email.
          </b-form-invalid-feedback>
        </b-form-group>
        <p>Или
          <b-link @click="$parent.login()">
            войти с постоянным аккаунтом
          </b-link>
        </p>
        <p>
          <b v-if="guest_form.error_text.length > 0">{{ this.guest_form.error_text }}</b>
        </p>
      </b-form>

    </b-modal>
    <div id="main-form" class="my-container"
      style="padding:1rem; text-align: center; vertical-align: middle; width: 100%; display: table; height: 100%; padding-top: 5rem">
      <div style="display: table-cell; vertical-align: middle; font-size: 18px; display: table-cell;">
        <span style="">
          <p
            style="font-size: 1.8rem; color: #8a8a8a; text-shadow: 0 0px 0px rgba(0,0,0,0.25), 0px 0px 10px rgba(0,0,0,0.22);">
            Система видео-конференций МИЭМ НИУ ВШЭ</p>
        </span>
        <div class="row justify-content-md-center">
          <div class="col-12 col-sm-12 col-md-8 col-xl-5 col-lg-5" style="padding: 10px;">
            <div class="row p-0 m-0">
              <b-col class="p-0">
                <b-form-input v-model="$parent.room_text" size="lg"
                  style="width: 100%; text-align: center; box-shadow: 0 0px 0px rgba(0,0,0,0.25), 0px 0px 10px rgba(0,0,0,0.22);"
                  placeholder="Введите имя комнаты" @keydown.native="enter_keydown_handler">
                </b-form-input>
              </b-col>
              <b-col v-if="!is_fill_room_name()" class="col-2 p-0">
                <b-button size="lg" variant="secondary" v-b-popover.hover="'Случайное имя'" @click="this.randomName">
                  <b-icon icon="shuffle" aria-hidden="true"></b-icon></b-button>
              </b-col>

            </div>
            <div class="row p-0 m-0">
              <b-form-invalid-feedback :state="this.validation_main_room_name()">
                Длина имени от 2 до 60 символов. Допустимы только символы латинского алфавита [a-z,A-Z], цифры [0-9] и
                символы
                ["_","-"].
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-8 col-xl-3 col-lg-3" style="padding: 10px;" v-if="!$parent.isMobile()">
            <b-button-group size="lg" style="width: 100%" v-if="$parent.is_auth()">
              <b-button variant="primary" style="width: 100%" @click="open_room" v-if="$parent.is_auth()"
                :disabled="!is_fill_room_name() || !validation_main_room_name()">
                Подключиться
              </b-button>
              <b-button v-b-modal.modal-invite variant="primary" @click="$parent.showModalInvite()">
                <b-icon icon="person-plus" aria-hidden="true"></b-icon>
              </b-button>
            </b-button-group>
            <b-button variant="primary" size="lg" style="width: 100%" @click="$parent.login()"
              v-if="!$parent.is_auth() && is_fill_room_name()"
              :disabled="!is_fill_room_name() || !validation_main_room_name()">
              Авторизоваться и подключиться
            </b-button>
            <b-button variant="primary" size="lg" style="width: 100%" @click="$parent.login()"
              v-if="!$parent.is_auth() && !is_fill_room_name()">
              Войти
            </b-button>

          </div>
        </div>
        <div class="row justify-content-md-center" v-if="!$parent.is_auth() && !$parent.isMobile()">
          <div class="col-12 col-sm-12 col-md-8 col-xl-8" style="padding: 10px;">
            <b-button variant="primary" size="lg" style="width: 100%" @click="this.showModalGuest"
              :disabled="!is_fill_room_name() || !validation_main_room_name()">
              Войти как Гость
            </b-button>
          </div>
        </div>
        <div class="row justify-content-md-center" v-if="$parent.isMobile()">
          <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="$parent.is_auth()">
            <b-button block variant="primary" style="width: 100%" @click="open_room_in_app"
              :disabled="!is_fill_room_name() || !validation_main_room_name()">
              Подключиться в приложении
            </b-button>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="$parent.is_auth()">
            <b-button block variant="primary" style="width: 100%; hight: 100%" @click="open_room"
              :disabled="!is_fill_room_name() || !validation_main_room_name()">
              Подключиться в браузере
            </b-button>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="!$parent.is_auth()">
            <b-button block variant="primary" style="width: 100%; hight: 100%" @click="this.showModalGuest"
              :disabled="!is_fill_room_name() || !validation_main_room_name()">
              Войти как Гость
            </b-button>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="!$parent.is_auth()">
            <b-button block variant="primary" style="width: 100%; hight: 100%" @click="$parent.login()">
              Авторизоваться
            </b-button>
          </div>
        </div>
        <!-- Контейнер для быстрой истории        -->
        <div v-if="$parent.is_auth()" class="row justify-content-md-center" style=" ">

          <div class="col-12 col-sm-12 col-md-8 col-xl-8 mb-0 " style="padding: 10px; height:19rem; max-height: 100%; ">

            <div id="history-container" v-if="is_history_loaded"
              style="overflow-y:auto; padding: 1rem; background-color: white; border-radius: 0.25rem; max-height: 100%">
              <b-list-group style=" max-height: 100%;  width:100%; background: #ffffff">
                <div class="v-border" v-for="(item, index) in this.history" :key="index + '2'">
                  <b-list-group-item size="sm" class="my-list-group-item" v-if="is_history_loaded">

                    <b-row align-v="center">
                      <b-col>
                        <b-row align-v="center">
                          <b-col @click="set_room(item['room_name'])">
                            <span class="mr-1" style="text-align: left; ">{{
                              dateFormat(new Date(Math.trunc(item["create_ts"] * 1000)), "%d.%m.%Y %H:%M:%S", false)
                            }}</span>
                          </b-col>
                          <b-col @click="set_room(item['room_name'])">
                            <span class="mr-2" style="text-align: left; word-wrap: break-word; max-width: 100px"><b>{{
                              item["room_name"] }}</b> </span>
                          </b-col>
                          <b-col @click="set_room(item['room_name'])">
                            <span class="mr-2" style="text-align: left;">[{{
                              get_duration_string(item["create_ts"], item["end_ts"])
                            }}]</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <p v-else>{{ this.data_message }}</p>

                </div>
              </b-list-group>
            </div>
            <p v-else>{{ this.data_message }}</p>
          </div>
        </div>
        <div v-if="$parent.is_auth()" class="row justify-content-center" style=" ">
          <div class="col-12 col-sm-12 col-md-8 col-xl-8" style="text-align: end">
            <b-link :to="{ name: 'history' }" style="font-size: 1rem">
              полная история
            </b-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

var container = document.querySelector('#jitsi-container');
var jitsi_text = document.querySelector('#jitsi-text');
var main_text = document.querySelector('#main-form');
var api = null;
//var is_auth_flag_glob = false
export default {
  watch: {},
  name: 'ConfPage',
  components: {},

  data() {
    return {
      guest_form: {
        code: "",
        room_name: "",
        guest_name: "",
        guest_email: "",
        guest_sub: "",
        error_text: ""
      },
      data_message: "",
      ts: null,
      history: [],
      messages: [],
      flag_loading: true,
      info_is_loaded: false,
      is_history_loaded: false,
      user_history: [],
    }
  },
  mounted() {
    //console.log(location.pathname.substring(1));

    this.$parent.room_text = location.pathname.substring(1);
    this.$parent.api_check_auth(this.open_room_event, this.open_guest_modal_event);
    //this.myUndefinedFunction();
    //Sentry.showReportDialog({ eventId: event.event_id });

    if (!this.ts) {
      this.ts = Date.now() / 1000;
    }

    let c_window = this;
    this.api_get_rooms_history("", c_window.ts, 0);

    //Sentry.captureMessage("Something went wrong");
  },
  computed: {
    validation_guest_room_name() {
      let format = /[^a-zA-Z0-9\-_]/;
      if (this.guest_form.room_name.length < 2 || this.guest_form.room_name.length > 60) return false;

      if (format.test(this.guest_form.room_name)) {
        return false;
      } else
        return true;
    },

    validation_code() {
      let format = /[^a-zA-Z0-9\-_]/;
      if (this.guest_form.code.length < 2 || this.guest_form.code.length > 17) return false;

      if (format.test(this.guest_form.code)) {
        return false;
      } else
        return true;
    },
    validation_guest_name() {
      if (this.guest_form.guest_name.length < 2 || this.guest_form.guest_name.length > 100) return false;
      return true;
    },
    validation_guest_email() {
      let format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return format.test(this.guest_form.guest_email);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$root.global_object.is_conf_now === true) {
      this.$bvModal.msgBoxConfirm('Конференция еще продолжается. Вы желаете выйти?', {
        title: 'Подтвердите действие',
        size: 'sm',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value == true) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(err => {
          console.log(err)
          // An error occurred
        })
    } else next(true);
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
  },
  methods: {
    set_room(name) {
      this.$parent.room_text = name;
      this.open_room_event();
    },
    removeElement: function (index) {
      this.history.splice(index, 1);
    },
    get_duration_string(start_ts, end_ts) {
      if (end_ts === "" || end_ts == null) {
        // alert("NULL");
        return "online";
      }
      let start_date = new Date(Math.trunc(start_ts * 1000));
      let end_date = new Date(Math.trunc(end_ts * 1000));
      let dur_sec = Math.trunc((end_date - start_date) / 1000);
      let seconds = dur_sec % 60; // Получаем секунды
      let minutes = dur_sec / 60 % 60; // Получаем минуты
      let hour = dur_sec / 60 / 60 % 60; // Получаем часы
      // Создаём строку с выводом времени
      let str_duration = `${Math.trunc(hour)}:${Math.trunc(minutes).toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}:${Math.trunc(seconds).toLocaleString('ru-RU', { minimumIntegerDigits: 2 })}`;
      return str_duration;

    },
    validation_main_room_name() {
      //return true;
      let format = /[^a-zA-Z0-9\-_]/;
      this.$parent.room_text = this.$parent.room_text.replace(' ','_');
      //alert(this.$parent.room_text);
      if (this.$parent.room_text.length > 60) return false;
      
      if (format.test(this.$parent.room_text)) {
        return false;
      } else
        return true;
    },
    enter_keydown_handler(event) {
      // this.$parent.room_text = this.$parent.room_text.replace(' ','_');
      if (event.which === 13) {
        if (this.validation_main_room_name())
          this.open_room();
      }
    },
    get_end_ts_string(ts) {
      // alert(ts.value)
      if (ts.value === "" || ts.value == null) {
        // alert("NULL");
        return ""
      }

      return this.dateFormat(new Date(Math.trunc(ts.value * 1000)), "%d.%m.%Y %H:%M:%S", false)
    },
    dateFormat: function (date, fstr, utc) {
      if (date === null)
        return ""
      utc = utc ? 'getUTC' : 'get';
      return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
          case '%Y':
            return date[utc + 'FullYear'](); // no leading zeros required
          case '%m':
            m = 1 + date[utc + 'Month']();
            break;
          case '%d':
            m = date[utc + 'Date']();
            break;
          case '%H':
            m = date[utc + 'Hours']();
            break;
          case '%M':
            m = date[utc + 'Minutes']();
            break;
          case '%S':
            m = date[utc + 'Seconds']();
            break;
          default:
            return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
      });
    },
    api_get_rooms_history(room_name, ts, page, limit = 10) {
      this.flag_loading = true;
      this.info_is_loaded = false;
      this.axios

        .get('/api/v1/get_rooms_history?room_name=' + room_name + '&ts=' + ts + '&page=' + page + '&limit=' + limit, {
          withCredentials: true
        })
        .then(response => {
          if (response.data) {
            this.info_is_loaded = true;
            this.flag_loading = false;
            this.history = response.data.history;
            //alert(response.data.all_events_count);
          } else {
            this.info_is_loaded = false;
            this.data_message = "Данные отсутствуют";
            this.flag_loading = false;
          }
        })
        .catch((error) => {
          this.info_is_loaded = false;
          this.data_message = "Данные отсутствуют";
          this.flag_loading = false;
          if (error.response) {
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          }
        });
      //this.flag_loading = false;
      this.is_history_loaded = true;
    },
    randomName() {
      //this.$parent.room_text = "123456";
      let result = '';
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      
      while (counter < 4) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      result +="-";
      while (counter < 8) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      result +="-";
      while (counter < 12) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      this.$parent.room_text = result;
    },
    showModalGuest() {
      this.guest_form.room_name = this.$parent.room_text;
      this.guest_form.error_text = "";
      if (this.$route.query.code)
        this.guest_form.code = this.$route.query.code;
      let guest_name = localStorage.getItem("guest_name");
      let guest_email = localStorage.getItem("guest_email");
      let guest_sub = localStorage.getItem("guest_sub");
      if (guest_name)
        this.guest_form.guest_name = guest_name;
      if (guest_email)
        this.guest_form.guest_email = guest_email;
      if (guest_sub)
        this.guest_form.guest_sub = guest_sub

      this.$bvModal.show("modal-guest-login");
    },
    is_fill_room_name() {
      if (this.$parent.room_text.length > 0)
        return true;
      else
        return false;
    },
    start_timer(call) {
      this.$parent.timer = setInterval(function () {
        call();

      }, 1000)
    },
    start_local_video_record(call, room_name) {
      this.$parent.status_timer = setInterval(function () {
        call(room_name);
      }, 60000)
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },

    iframeNow() {
      jitsi_text.style.display = "none";
      main_text.style.display = "none";
      container.style.display = "block";
      // this.api.getLivestreamUrl().then(livestreamData => {
      //   alert(JSON.stringify(livestreamData));
      // });
      this.start_timer(this.$parent.calc_timer);
      this.start_local_video_record(this.$parent.api_get_room_status, this.$parent.room_text);
      this.$parent.is_conf_now = true;
      document.title = "Комната: " + this.$parent.room_text;
      this.$root.global_object.is_conf_now = true;
      this.$root.global_object.left_room = this.left_room;
      api.isAudioMuted().then(muted => {
        this.$parent.record_mic_muted(muted);
      });

    },
    conference_error_listener(event) {
      console.log(JSON.stringify(event))
      this.$parent.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить подключение позже.");
    },
    mute_event_listener(event) {
      //alert(event.muted);
      this.$parent.record_mic_muted(event.muted);
    },
    role_changed_listener(event) {
      this.$parent.conf_states.current_role = event.role
    },
    recording_status_change_listener(event) {
      this.$parent.conf_states.is_cloud_record_now = event.on

    },
    video_Conference_Joined_listener(/*event*/) {
      //alert("I joined "+ event.id)
    },
    left_room() {

      //console.log(event.roomName);

      //alert("Left room " + event.roomName);
      //
      //     return;
      container.style.display = "none";
      jitsi_text.style.display = "none";
      main_text.style.display = "table";
      //this.add_to_history(this.$parent.room_text, this.$parent.timer_str);
      this.api_get_rooms_history("", Date.now() / 1000, 0, 10);
      this.$parent.timer_sec = 0;
      if (api)
        api.dispose();
      history.pushState({ param: 'Value' }, '', '/');
      clearInterval(this.$parent.timer);
      clearInterval(this.$parent.status_timer);
      this.$parent.nonempty_local_records = false;
      this.$parent.is_conf_now = false;
      this.$root.global_object.is_conf_now = false;
      this.$parent.record_stop();
      this.$root.global_object.left_room = null;
      this.$parent.set_default_title();
    },

    open_room() {
      if (this.$parent.room_text === "" || !this.validation_main_room_name())
        return;
      this.api_get_token(this.$parent.room_text);
    },
    open_room_as_guest() {
      localStorage.setItem("guest_name", this.guest_form.guest_name);
      localStorage.setItem("guest_email", this.guest_form.guest_email);
      this.api_get_token_for_guest(this.guest_form.room_name, this.guest_form.code, this.guest_form.guest_name, this.guest_form.guest_email, this.guest_form.guest_sub);
    },
    open_room_event() {
      //alert("openroomevent");
      if (this.$parent.room_text.length > 0) {

        if (this.$parent.is_auth() && !this.$parent.isMobile()) {
          this.open_room(this.$parent.room_text)
        }
        if (!this.$parent.is_auth()) {
          let flag_guest = true
          if (this.$route.query.guest === undefined)
            flag_guest = false
          if (flag_guest) this.showModalGuest()
        }
      }
    },
    open_guest_modal_event() {
      if (!this.$parent.is_auth()) {
        let flag_guest = true
        if (this.$route.query.guest === undefined)
          flag_guest = false
        if (flag_guest) this.showModalGuest()
      }
    },
    /**
     * Запрашивает токен для авторизованных пользователей для входа на конференцию.
     * В случае успеха переходим сразу в комнату.
     * @param room_name
     * @param in_mobile если True, то переходим в мобильное приложение.
     */
    api_get_token(room_name, in_mobile = false) {
      let data = { room_name: room_name };
      if (this.$parent.user_avatar_checked == true)
        data.load_avatar = this.$parent.user_avatar_checked;

      this.axios
        .post('/api/v1/get_token?' + this.$parent.getRandomInt(3000000).toString(), data, {
          withCredentials: true,
          headers: {
            'X-CSRF-TOKEN': this.getCookie('csrf_access_token'),
          }
        })
        .then(response => {
          this.$parent.room_text = response.data.room_name;
          this.$parent.room_full_link = window.location.origin + "/" + this.$parent.room_text
          if (in_mobile) {
            let link = "org.jitsi.meet://" + response.data.domain + "/" + response.data.room_name + "?jwt=" + response.data.JWT;
            this.$parent.go_link(link);
            return;
          }

          //this.user_name = response.data.name;
          container = document.querySelector('#jitsi-container');
          jitsi_text = document.querySelector('#jitsi-text');
          main_text = document.querySelector('#main-form');
          container.innerHTML = "";
          main_text.style.display = "none";
          jitsi_text.style.display = "table";
          container.style.display = "none";

          history.pushState({ param: 'Value' }, this.$parent.room_text, this.$parent.room_text);
          let options = {
            "roomName": response.data.room_name,
            "parentNode": container,
            "jwt": response.data.JWT,
            // "width": 600,
            // "height": 400,
            "onload": this.iframeNow,
            configOverwrite: {
              disableDeepLinking: true,
              startWithAudioMuted: true,
              startWithVideoMuted: true
            },
          };

          // eslint-disable-next-line no-undef
          api = new JitsiMeetExternalAPI(response.data.domain, options);
          this.$parent.conf_states.api = api;
          api.addEventListeners({
            videoConferenceLeft: this.left_room
          });
          api.addEventListeners({
            audioMuteStatusChanged: this.mute_event_listener
          });
          api.addEventListeners({
            errorOccurred: this.conference_error_listener
          });
          api.addEventListeners({
            participantRoleChanged: this.role_changed_listener
          });
          api.addEventListeners({
            recordingStatusChanged: this.recording_status_change_listener
          });
          api.addEventListeners({
            videoConferenceJoined: this.video_Conference_Joined_listener
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401)
              this.$parent.showAlertMsgBox();
            else
              this.$parent.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить запрос позже.");
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    },
    /**
     * Запрашивает токен для гостевых пользователей для входа на конференцию.
     * В случае успеха переходим сразу в комнату.
     * @param room_name
     * @param in_mobile если True, то переходим в мобильное приложение.
     */
    api_get_token_for_guest(room_name, code, guest_name, guest_email, guest_sub, in_mobile = false) {
      this.axios
        .post('/api/v1/get_token_for_guest?' + this.$parent.getRandomInt(3000000).toString(),
          {
            room_name: room_name,
            code: code,
            guest_name: guest_name,
            guest_email: guest_email,
            guest_sub: guest_sub
          }, {
          withCredentials: false,
          headers: {}
        })
        .then(response => {
          this.$parent.room_text = response.data.room_name;
          localStorage.setItem("guest_sub", response.data.guest_sub);
          this.guest_form.guest_sub = response.data.guest_sub;
          if (response.data.result == false && response.data.result_code == 425) {
            //alert("Wait moderators");
            this.guest_form.error_text = "Организатор еще не начал встречу. Попробуйте подключиться позднее.";
            return;
          }
          this.$parent.room_full_link = window.location.origin + "/" + this.$parent.room_text
          if (in_mobile) {
            let link = "org.jitsi.meet://" + response.data.domain + "/" + response.data.room_name + "?jwt=" + response.data.JWT;
            this.$parent.go_link_blank(link);
            return;
          }

          //this.user_name = response.data.name;
          container = document.querySelector('#jitsi-container');
          jitsi_text = document.querySelector('#jitsi-text');
          main_text = document.querySelector('#main-form');
          container.innerHTML = "";
          main_text.style.display = "none";
          jitsi_text.style.display = "table";
          container.style.display = "none";
          this.$bvModal.hide("modal-guest-login");
          history.pushState({ param: 'Value' }, this.$parent.room_text, this.$parent.room_text);
          let options = {
            "roomName": response.data.room_name,
            "parentNode": container,
            "jwt": response.data.JWT,
            // "width": 600,
            // "height": 400,
            "onload": this.iframeNow,
            configOverwrite: {
              disableDeepLinking: true,
              startWithAudioMuted: true,
              startWithVideoMuted: true
            },
          };

          // eslint-disable-next-line no-undef
          api = new JitsiMeetExternalAPI(response.data.domain, options);
          this.$parent.conf_states.api = api;
          api.addEventListeners({
            videoConferenceLeft: this.left_room
          });
          api.addEventListeners({
            audioMuteStatusChanged: this.mute_event_listener
          });
          api.addEventListeners({
            errorOccurred: this.conference_error_listener
          });
          api.addEventListeners({
            participantRoleChanged: this.role_changed_listener
          });
          api.addEventListeners({
            recordingStatusChanged: this.recording_status_change_listener
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401)
              this.$parent.showAlertMsgBox("Код пропуска недействительный. Проверьте правильность введенных данных.");
            else
              this.$parent.showAlertMsgBox("Нет подключения. Проверьте правильность введенных данных.");
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    },
    open_room_in_app() {
      if (this.$parent.room_text === "" || !this.validation_main_room_name())
        return;
      this.api_get_token(this.$parent.room_text, true);
    }
    ,
  },
  props: {
    // global_object: Object,
  }
}
import "@/assets/css/main_style.css";
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.menu-items {
  text-align: left;
}


.my-list-group-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.my-list-group-item:hover {
  background-color: #eeeff4;
}

.btn-primary {
  background-color: #0050cf;
  border-color: #0050cf;
}

.btn-primary {
  color: #fff;
  background-color: #0050cf;
  border-color: #0050cf;
}

.nav-pills .nav-link.active {
  background-color: #0050cf;
}

.my-container {
  background-color: #eeeff4;
}

.active-icon:hover {
  transform: scale(1.75);
}
</style>
