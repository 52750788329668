<template>
  <div style="padding-bottom: 1rem">
    <b-form @submit.stop.prevent v-if="this.editMode">
      <b-form-group>
        <label class="mr-sm-2" label-for="room-name">Имя комнаты:</label>
        <b-form-input placeholder="Введите имя комнаты" debounce="300" v-model="form.room_name"
                      :state="validation_room_name"
                      id="room-name"
                      required></b-form-input>
        <b-form-invalid-feedback :state="validation_room_name">
          Длина имени от 2 до 60 символов. Допустимы только символы латинского алфавита [a-z,A-Z], цифры [0-9] и символы
          ["_","-"].
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-input-group prepend="Тип:" class="mb-0 mb-sm-0">
          <b-form-select v-model="form.pass_type" :state="validation_data_range" id="pass_type" :options="pass_types"
                         required></b-form-select>
        </b-input-group>
        <b-form-invalid-feedback :state="validation_data_range">
          Укажите срок действия пропуска.
        </b-form-invalid-feedback>
      </b-form-group>
      <b-collapse :visible="is_custom_type()" id="collapse-custom-pass-code">

        <b-row>
          <b-col sm="12" md="12" lg="12" style="padding-bottom: 1rem">
            <label class="mr-sm-2" label-for="datepicker_range">Срок действия пропуска:</label>
            <DatePicker
                id="datepicker_range"
                ref="datepicker_range"
                v-model="form.date_range"
                type="datetime"
                placeholder="Утановите временной диапазон"
                range
                :show-time-panel="showTimeRangePanel"
                value-type="timestamp"
                @close="handleRangeClose"
                class="form-control"
                style="padding: 0"
            >
              <template v-slot:header="{ emit }">
                <button class="mx-btn mx-btn-text" @click="selectNextTime(emit, 2)">2 часа</button>
                <button class="mx-btn mx-btn-text" @click="selectNextTime(emit, 24)">Сутки</button>
                <button class="mx-btn mx-btn-text" @click="selectNextTime(emit, 168)">Неделя</button>
              </template>
              <template v-slot:footer>
                <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                  {{ showTimeRangePanel ? 'Выбрать даты' : 'Указать время' }}
                </button>
                <button class="mx-btn mx-btn-text" @click="timePickerDone">
                  {{ 'Готово' }}
                </button>
              </template>
            </DatePicker>
            <b-form-invalid-feedback :state="validation_data_range">
              Срок действия пропуска не может быть пустым или больше 100 дней.
            </b-form-invalid-feedback>

          </b-col>
          <b-col sm="12" md="12" lg="6" style="padding-bottom: 1rem">
            <label label-for="max_usage">Макс. использований (0 - без ограничений):</label>
            <b-form-input v-model="form.max_usage" id="max_usage" type="number" min="0"></b-form-input>

            <!--            <b-input-group prepend=". (0 - нет):" class="mb-0 mb-sm-0" description="We'll never share your email with anyone else.">-->

            <!--            </b-input-group>-->
          </b-col>
          <b-col sm="12" md="12" lg="6" style="padding-bottom: 1rem">
            <label label-for="comment_area">Комментарий к пропуску:</label>
            <b-form-textarea
                v-model="form.comment"
                size="sm"
                rows="1"
                max-rows="1"
                id="comment_area"
                placeholder="Комментарий к пропуску"
            ></b-form-textarea>
          </b-col>
          <b-col sm="12" md="12" lg="6" style="padding-bottom: 1rem">
            <b-form-checkbox v-model="form.allow_first_login" name="check-button">
              Разрешить вход в пустую комнату
            </b-form-checkbox>
            <b-form-invalid-feedback :state="validation_allow_first_login">
              Внимание. Данная опция позволяет гостям пользоваться комнатой без ограничений.
            </b-form-invalid-feedback>
          </b-col>
          <b-col sm="12" md="12" lg="6" style="padding-bottom: 1rem">
            <b-form-checkbox v-model="form.active" name="check-button">
              Пропуск активен
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-collapse>
      <b-row>
        <b-col cols="12">
          <b-form-invalid-feedback :state="this.errorMessage.length==0" style="text-align: right">
            {{ this.errorMessage }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="auto" class="p-1">
          <b-overlay
              :show="editorBusy"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"

          >
            <b-button size="sm" variant="primary" :disabled="!(validation_room_name && validation_data_range)"
                      @click="createCode()">
              {{ form.code && form.code.length > 0 ? 'Сохранить' : 'Создать' }}
            </b-button>
          </b-overlay>
        </b-col>
        <b-col cols="auto" class="p-1 pr-3">
          <b-overlay
              :show="editorBusy"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
          >
            <b-button size="sm" variant="secondary" v-if="passCode.code" @click="()=>{this.editMode=false}">
              Отмена
            </b-button>
          </b-overlay>
        </b-col>
      </b-row>
    </b-form>
    <span v-if="!this.editMode">
      <b-row class="p-2" align-h="between">
        <b-col xl="3" lg="4" sm="6" md="6">
          <span id="c_rn" style="font-size: small"><b>{{ passCode.room_name }}</b></span>
        </b-col>
        <b-col xl="4" lg="4" sm="12" md="12">
          <span style="font-size: small; line-height: 1">
            [{{
              dateFormat(new Date(Math.trunc(passCode.start_ts * 1000)), "%d.%m.%Y %H:%M", false)
            }} ~ {{ dateFormat(new Date(Math.trunc(passCode.end_ts * 1000)), "%d.%m.%Y %H:%M", false) }}]
          </span>
        </b-col>
        <b-col v-if="passCode.allow_first_login">
          <b-badge variant="danger" pill size="md" v-b-popover.hover.right="'Разрешен вход в комнату без модератора'">Не
            безопасен
          </b-badge>
        </b-col>
        <b-col v-if="passCode.active">
          <b-badge variant="success" pill size="md">Активен</b-badge>
        </b-col>
        <b-col v-if="!passCode.active">
          <b-badge variant="secondary" pill size="md">Не активен</b-badge>
        </b-col>
        <b-col v-if="check_end_ts">
          <b-badge variant="warning" pill size="md">Истёк срок</b-badge>
        </b-col>
        <b-col v-if="check_start_ts">
          <b-badge variant="warning" pill size="md">Срок не наступил</b-badge>
        </b-col>
        <b-col cols="auto">
          <b-badge variant="primary" pill size="md" @click="showDetails" href="#">
            {{ this.showDetailsPanel ? 'Скрыть' : 'Подробнее' }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row align-h="between">
      <b-col cols="12">
        <b-collapse :visible="this.showDetailsPanel" id="collapse-custom-pass-code">
          <b-card>
            <b-row align-h="between">
              <b-col sm="12" md="12" lg="12"><b>Имя комнаты: </b>{{ passCode.room_name }}</b-col>
              <b-col sm="12" md="12" lg="12"><b>Срок действия: </b>{{
                  '[' +
                  dateFormat(new Date(Math.trunc(passCode.start_ts * 1000)), '%d.%m.%Y %H:%M', false)
                  + ' ~ ' + dateFormat(new Date(Math.trunc(passCode.end_ts * 1000)), '%d.%m.%Y %H:%M', false) + ']'
                }}</b-col>
            </b-row>
            <span v-if="passCode.code && passCode.code.length>0">
                <p class="my-2">
                  <b>Поделитесь ссылкой на встречу, чтобы пригласить гостей:</b>
                </p>
                <b-row align-h="between">
                  <b-col cols="6">
                    <b-form-textarea rows="3" id="full-link-plaintext" ref="full-link-plaintext" plaintext
                                     style="background-color: #eeeff4; padding: 3px; border-radius: 3px;"
                                     :value="this.getFullPassCodeLink"></b-form-textarea>
                  </b-col>
                  <b-col cols="6">
                    <b-row align-h="start" class="mb-3">
                      <b-col cols="auto" md="2" class="p-1">
                        <!--        <p class="card-text">{{ this.room_full_link }}</p>-->
                        <b-button variant="primary" size="sm" @click="shareAsCopyLink()"
                                  v-b-popover.hover.top="'Копировать ссылку'">
                          <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
                        </b-button>
                      </b-col>
                      <b-col cols="auto" md="2" class="p-1">
                        <!--        <p class="card-text">{{ this.room_full_link }}</p>-->
                        <b-button variant="primary" size="sm" @click="shareAsShareLinkTo()"
                                  v-b-popover.hover.top="'Поделиться'">
                          <b-icon icon="share" aria-hidden="true"></b-icon>
                        </b-button>
                      </b-col>
                      <b-col cols="auto" md="2" class="p-1">
                        <!--        <p class="card-text">{{ this.room_full_link }}</p>-->
                        <b-button variant="primary" size="sm" @click="shareAsMailLinkTo()"
                                  v-b-popover.hover.top="'Письмо'">
                          <b-icon icon="envelope" aria-hidden="true"></b-icon>
                        </b-button>
                      </b-col>
                      <b-col cols="auto" md="2" class="p-1">
                        <b-dropdown size="sm" variant="primary" id="dropdown-calendar" class="mr-1"
                                    v-b-popover.hover.top="'Добавить в календарь'" no-caret>
                          <template #button-content>
                            <b-icon icon="calendar3" aria-hidden="true"></b-icon>
                          </template>
                          <b-dropdown-item-button @click="shareAsYandexCalendarLink"><span style="color: #f33">Я</span>ндекс
                            календарь
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="shareAsHseCalendarLink"><span
                              style="color: #0078d4">ВШЭ</span>
                            календарь
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="shareAsGoogleCalendarLink"><span
                              style="color: #4285f4">G</span>oogle
                            календарь
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="shareAsOutlookCalendarLink"><span
                              style="color: #0078d4">O</span>utlook
                            календарь
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="shareAsICSFile">ICS файл</b-dropdown-item-button>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </span>
            <b-row align-h="between">
              <b-col cols="6" v-if="passCode.code && passCode.code.length > 0"><b>Код пропуска: </b>{{ passCode.code }}
              </b-col>
              <b-col cols="6">
                <b>Создан: </b>{{
                  dateFormat(new Date(Math.trunc(passCode.create_ts * 1000)), "%d.%m.%Y %H:%M", false)
                }}
              </b-col>
              <b-col cols="6">
                <b>Создал: </b>{{
                  passCode.user_name
                }}
              </b-col>
              <!--      <b-col cols="6"><b>Срок действия: </b> с-->
              <!--        {{ dateFormat(new Date(Math.trunc(passCode.start_ts * 1000)), "%d.%m.%Y %H:%M", false) }} по-->
              <!--        {{ dateFormat(new Date(Math.trunc(passCode.end_ts * 1000)), "%d.%m.%Y %H:%M", false) }}-->
              <!--      </b-col>-->
              <b-col lg="6" sm="12" md="12"><b>Количество использований: </b>{{
                  passCode.usage_counter
                }}{{ this.passCode.max_usage > 0 ? ' из ' + this.passCode.max_usage : '' }}
              </b-col>

              <b-col cols="12"><b>Использован последний раз:
              </b>{{ dateFormat(new Date(Math.trunc(passCode.update_ts * 1000)), "%d.%m.%Y %H:%M:%S", false) }}
              </b-col>
              <b-col cols="12" v-if="passCode.comment && passCode.comment.length>0"><b>Комментарий: </b>{{
                  passCode.comment
                }}</b-col>
            </b-row>
            <b-row align-h="end" v-if="passCode.code && passCode.code.length>0">
              <b-col cols="auto" class="p-1">
                <b-overlay
                    :show="editorBusy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
<!--                  Кнопка для редактирования-->
                  <b-button size="sm" variant="primary" @click="this.editCode" v-b-popover.hover.left="'Редактировать'">
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>
      </span>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import * as ics from 'ics';

export default {
  props: {
    pass_code_json: {
      type: Object,
      default: null
    },
    current_room_name: {
      type: String,
      default: ""
    }
  },
  comments: {DatePicker},
  name: "PassCodeEditor",
  data() {
    return {
      errorMessage: "",
      editMode: false,
      passCode: {
        code: "",
        room_name: '',
        allow_first_login: false,
        active: true,
        max_usage: 0,
        comment: "",
        create_ts: Date.now() / 1000,
        update_ts: Date.now() / 1000,
        start_ts: Date.now() / 1000,
        end_ts: Date.now() / 1000,
        usage_counter: 0,
        id: 0,
        user_name: ""
      },
      editorBusy: false,
      form: {
        code: null,
        id: null,
        room_name: '',
        pass_type: 1,
        allow_first_login: false,
        active: true,
        max_usage: 0,
        comment: "",
        date_range: [],
      },
      showTimeRangePanel: false,
      showDetailsPanel: false,
      pass_types: [{text: 'Пропуск на 2 ч.', value: 1},
        {text: 'Пропуск на 1 сутки', value: 2},
        {text: 'Настраиваемый', value: 0},
      ]
    }
  },
  computed: {
    check_end_ts() {
      let current_date = Date.now() / 1000.0;
      return current_date > this.passCode.end_ts;
    },
    check_start_ts() {
      let current_date = Date.now() / 1000.0;
      return current_date < this.passCode.start_ts;
    },
    getFullPassCodeLink() {
      return window.location.origin + "/" + this.passCode.room_name + "?guest&code=" + this.passCode.code;
    },
    validation_room_name() {
      // var format = /[!@#$%^&*()+\-=\\[\]{};':"\\|,.<>\\/? ]/;
      let format = /[^a-zA-Z0-9\-_]/
      if (this.form.room_name.length < 2 || this.form.room_name.length > 60) return false;

      if (format.test(this.form.room_name)) {
        return false;
      } else
        return true;
      // return this.room_name.length > 4 && this.room_name.length < 13
    },

    validation_data_range() {
      if (this.form.pass_type !== 0)
        return true;
      else {
        if (this.form.date_range.length === 2) {
          if (typeof this.form.date_range[0] == 'number' && typeof this.form.date_range[1] == 'number') {
            if (((this.form.date_range[1] - this.form.date_range[0]) / 86400000) > 100)
              return false;
            return true;
          } else
            return false;
        }
        return false
      }
      //this.$root.$emit('bv::toggle::collapse', 'my-collapse-id')
      // return false;
    },
    validation_allow_first_login() {
      return !this.form.allow_first_login;
    }

  },
  mounted() {
    if (this.pass_code_json)
      this.setPassCodeFromJSON(this.pass_code_json)
    else {
      this.editCode();
      this.showDetailsPanel = true;
      this.form.pass_type = 1;
      if (this.current_room_name.length > 0)
        this.form.room_name = this.current_room_name;
    }
  },
  methods: {
    setPassCodeFromJSON(json) {
      this.passCode.code = json.code;
      this.passCode.room_name = json.room_name;
      this.passCode.allow_first_login = json.allow_first_login;
      this.passCode.active = json.active;
      this.passCode.max_usage = json.max_usage;
      this.passCode.comment = json.comment;
      this.passCode.create_ts = json.create_ts;
      this.passCode.update_ts = json.update_ts;
      this.passCode.start_ts = json.start_ts;
      this.passCode.end_ts = json.end_ts;
      this.passCode.usage_counter = json.usage_counter;
      this.passCode.id = json.id;
      this.passCode.user_name = json.user_name;
    },
    editCode() {
      this.editMode = true;
      this.form.code = this.passCode.code;
      this.form.room_name = this.passCode.room_name;
      this.form.pass_type = 0;
      this.form.active = this.passCode.active;
      this.form.comment = this.passCode.comment;
      this.form.allow_first_login = this.passCode.allow_first_login;
      this.form.max_usage = this.passCode.max_usage;
      this.form.date_range[0] = this.passCode.start_ts * 1000;
      this.form.date_range[1] = this.passCode.end_ts * 1000;
      this.form.id = this.passCode.id;
    },
    makeToast(append = false, text, variant = null) {
      // this.toastCount++
      this.$bvToast.toast(text, {
        title: 'Уведомление',
        autoHideDelay: 3000,
        appendToast: append,
        variant: variant
      })
    },
    shareAsMailLinkTo() {
      let uri = "mailto:";
      uri += "?subject=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&body=";
      uri += encodeURIComponent(this.getBodyForShare());
      window.open(uri, "_blank");
    },
    shareAsShareLinkTo() {
      if (navigator.share && navigator.canShare && navigator.canShare({url: this.getFullPassCodeLink})) {
        try {
          navigator.share({
            title: this.getSubjectForShare(),
            text: this.passCode.user_name + " приглашает Вас присоединиться к конференции.",
            url: this.getFullPassCodeLink
          }).then(() => {
            this.makeToast(true, 'Приглашение готово.', "success");
            console.log('Thanks for sharing!');
          }).catch((error) => {
            console.log("Sharing failed", error);
            //alert(error)
          });
        } catch (err) {
          console.log("Sharing failed", err);
          //alert(err);
          this.makeToast(true, 'Ваш браузер не поддерживает данную возможность!', "danger");
        }
      } else {
        this.makeToast(true, 'Ваш браузер не поддерживает данную возможность!', "danger");
        // fallback

      }
    },
    shareAsCopyLink() {
      let copyTextarea = this.$refs["full-link-plaintext"];
      copyTextarea.focus();
      copyTextarea.select();

      try {
        let successful = document.execCommand('copy');
        if (successful)
          this.makeToast(true, "Ссылка скопирована в буфер обмена", "success");
        else
          this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
      } catch (err) {
        this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
        console.log('Ссылка не скопирована. Что-то пошло не так.');
      }
    },
    getSubjectForShare() {
      return "Приглашение присоединиться к встрече";
    },
    getBodyForShare() {
      return this.passCode.user_name + " приглашает Вас присоединиться к конференции.\r\n\r\n" +
          "Ссылка для подключения: \r\n" + this.getFullPassCodeLink +
          "\r\n\r\n Имя комнаты: " + this.passCode.room_name +
          "\r\n Код пропуска: " + this.passCode.code;
    },
    shareAsOutlookCalendarLink() {
      let uri = "https://outlook.live.com/calendar/0/deeplink/compose";
      uri += "?subject=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&body=";
      uri += encodeURIComponent(this.getBodyForShare());
      uri += "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent";
      this.$root.global_object.go_link_blank(uri);
    },
    shareAsHseCalendarLink() {
      let uri = "https://mail2.hse.ru/?path=/calendar/action/compose";
      uri += "&subject=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&location=";
      uri += encodeURIComponent(this.getFullPassCodeLink);
      uri += "&body=";
      uri += encodeURIComponent(this.getBodyForShare());
      this.$root.global_object.go_link_blank(uri);
    },
    shareAsYandexCalendarLink() {
      let uri = "https://calendar.yandex.ru/week/create";
      uri += "?name=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&description=";
      uri += encodeURIComponent(this.getBodyForShare());
      this.$root.global_object.go_link_blank(uri);
    },
    shareAsICSFile() {
      let desc = this.getBodyForShare();
      let now = new Date();

      ics.createEvent({
        productId: window.location.origin,
        title: this.getSubjectForShare(),
        url: this.room_full_link,
        organizer: {name: this.user_name, email: this.user_email},
        description: desc,
        start: [now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes()],
        //busyStatus: 'FREE',
        duration: {minutes: 60}
      }, (error, value) => {
        if (error) {
          console.log(error)
        } else {
          let blob = new Blob([value], {type: 'text/calendar;charset=utf-8,'});
          if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, "invite" + ".ics");
          } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
              link.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURI(value));
              link.setAttribute("download", "invite" + ".ics");
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        }
      });
    },
    shareAsGoogleCalendarLink() {
      let uri = "https://calendar.google.com/calendar/render?action=TEMPLATE";
      uri += "&text=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&details=";
      uri += encodeURIComponent(this.getBodyForShare());
      this.$root.global_object.go_link_blank(uri);
    },
    showDetails() {
      this.showDetailsPanel = !this.showDetailsPanel;
    },
    // my_alert() {
    //   alert(this.form.room_name);
    // },
    createCode() {
      //let form_request = Object.assign({},this.form);
      let form_request = {
        code: this.form.code,
        id: this.form.id,
        room_name: this.form.room_name,
        pass_type: this.form.pass_type,
        allow_first_login: this.form.allow_first_login,
        active: this.form.active,
        max_usage: this.form.max_usage,
        comment: this.form.comment,
        date_range: [],
      };
      form_request.date_range[0] = this.form.date_range[0] / 1000.0;
      form_request.date_range[1] = this.form.date_range[1] / 1000.0;
      this.api_create_pass_code(form_request);
    },
    api_create_pass_code(form_req) {
      this.editorBusy = true;
      this.axios
          .post('/api/v1/create_pass_code?' + this.$root.global_object.methods.getRandomInt(3000000).toString(), form_req, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then(response => {
            this.setPassCodeFromJSON(response.data);
            //alert(this.passCode.code);
            this.editorBusy = false;
            this.errorMessage = "";
            this.editMode = false;
            this.$emit("updateEvent");
          })
          .catch((error) => {
            this.editorBusy = false;
            if (error.response) {
              if (error.response.status == 401) {
                this.errorMessage = "Требуется авторизация!";
                this.$parent.showAlertMsgBox();
              } else if (error.response.status == 400) {
                this.errorMessage = "Ошибка в запросе!";
              } else
                this.errorMessage = "Нет соединения с сервером. Попробуйте повторить запрос позже."
              //  this.$parent.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить запрос позже.");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
    },
    dateFormat: function (date, fstr, utc) {
      utc = utc ? 'getUTC' : 'get';
      return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
          case '%Y':
            return date[utc + 'FullYear'](); // no leading zeros required
          case '%m':
            m = 1 + date[utc + 'Month']();
            break;
          case '%d':
            m = date[utc + 'Date']();
            break;
          case '%H':
            m = date[utc + 'Hours']();
            break;
          case '%M':
            m = date[utc + 'Minutes']();
            break;
          case '%S':
            m = date[utc + 'Seconds']();
            break;
          default:
            return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
      });
    },
    is_custom_type() {
      return this.form.pass_type == 0;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
    timePickerDone() {
      this.$refs.datepicker_range.closePopup();
    },
    selectNextTime(emit, number_of_hours) {
      const start = new Date();
      const end = new Date();
      end.setTime(end.getTime() + number_of_hours * 3600 * 1000);
      const date = [start, end];
      emit(date);
    }
  }

}
</script>
<style>
.mx-datepicker-range {
  width: 100% !important;
}
</style>

<style scoped>

</style>