import VueRouter from 'vue-router'

Vue.use(VueRouter)

import ConfPage from '../components/ConfPage';
import RoomsHistory from '../components/RoomsHistory';
import RoomInfo from '../components/RoomInfo';
import PassCodes from "../components/PassCodes";
import FAQ from "../components/FAQ";
import Monitoring from "../components/Monitoring";
import MonitoringUsers from "../components/MonitoringUsers";
import NotFound from "../components/NotFound";
import Vue from "vue";
import PrivateChart from "../components/PrivateChart";


const global_object = {
    is_conf_now: false,
    current_menu: "item-conf",
    bread_items: [],
    left_room: null,
    //api_check_auth: null,

    methods: {

        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
        isMobile() {
            let vall = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i);
            if (vall == null) {
                return false;
            }
            return true;
        },
        get_duration_string(start_ts, end_ts) {
            let end_date = null;
            let is_online = false
            if (end_ts === "" || end_ts == null) {
                // alert("NULL");
                is_online = true;
                end_date = new Date();
                //return ["online", true];
            } else
                end_date = new Date(Math.trunc(end_ts * 1000));
            let start_date = new Date(Math.trunc(start_ts * 1000));
            let dur_sec = Math.trunc((end_date - start_date) / 1000);
            let seconds = dur_sec % 60; // Получаем секунды
            let minutes = dur_sec / 60 % 60; // Получаем минуты
            let hour = dur_sec / 60 / 60; // Получаем часы
            // Создаём строку с выводом времени
            let str_duration = `${Math.trunc(hour)}:${Math.trunc(minutes).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}:${Math.trunc(seconds).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}`;
            return [str_duration, is_online];

        }

    }
}
const routes = [
    {path: '', component: ConfPage, name: "main"},
    {
        path: '/lp/history/', component: RoomsHistory, name: "history", props: route =>
            ({
                    page: route.query.page, room_name: route.query.room_name,
                    start_ts: route.query.start_ts, end_ts: route.query.end_ts,
                    by_room: route.query.by_room, only_online: route.query.only_online,
                    not_fast: route.query.not_fast,
                    video_available: route.query.video_available
                }
            ),
    },
    {
        path: '/lp/history/private_users',
        component: PrivateChart,
        name: "PrivateChart"
    },
    {
        path: '/lp/history/:room_name', component: RoomInfo, name: "room-info",
        children: [
            {
                path: ":time",
                component: RoomInfo,
                name: "room-info-ts",
                props: route =>
                    ({ tab: route.query.tab })
            }
        ]
    },
    {path: '/lp/faq/', component: FAQ, name: "faq"},
    {path: '/lp/admin/monitoring', component: Monitoring, name: "admin_monitoring"},
    {
        path: '/lp/admin/rooms_history', component: RoomsHistory, name: "admin_rooms_history", props: route =>
            ({
                    page: route.query.page, room_name: route.query.room_name,
                    start_ts: route.query.start_ts, end_ts: route.query.end_ts,
                    by_room: route.query.by_room, only_online: route.query.only_online,
                    not_fast: route.query.not_fast,
                    video_available: route.query.video_available
                }
            ),
    },
    {path: '/lp/admin/users', component: MonitoringUsers, name: "admin_monitoring_users", props: route =>
            ({
                    page: route.query.page, search_request: route.query.search_request, sort: route.query.sort

                }
            )
    },
    {
        path: "/lp/pass_codes/", component: PassCodes, name: "pass_codes", props: route =>
            ({page: route.query.page},
                {filter_room_name: route.query.filter_room_name})
    },
    {path: '/:id', component: ConfPage, name: "conf"},
    {path: '*', component: NotFound, name: "NotFound"}

]
const router = new VueRouter({
    mode: 'history',
    routes // сокращённая запись для `routes: routes`
})
const bread_items_list = {
    'main': [],
    'history': [
        {
            text: 'История',
            active: true
        }
    ],
    'PrivateChart':[
        {
            text: 'Приватный график',
            active: true
        }
    ],
    'room-info-ts': [
        {
            text: 'История',
            to: {name: 'history'}
        }
    ],
    'pass_codes': [
        {
            text: 'Пропуска для гостей',
            active: true
        }
    ],
    'faq': [
        {
            text: 'Часто задаваемые вопросы',
            active: true
        }
    ],
    'admin_monitoring': [
        {
            text: 'Мониторинг',
            active: true
        }
    ],
    'admin_monitoring_users': [
        {
            text: 'Администрирование',
            active: true
        },
        {
            text: 'Пользователи',
            active: true
        }
    ],
    'admin_rooms_history': [
        {
            text: 'Администрирование',
            active: true
        },
        {
            text: 'История комнат',
            active: true
        }
    ]

}
router.beforeEach((to, from, next) => {
    switch (from.name) {
        case "main":
        case "conf":
            if (to.name == "main") {
                //to.path = from.path;
                return;
            }

            if (global_object.left_room) {
                global_object.left_room();
            }
            break;
    }
    //alert(to.path);
    //alert(to.name)
    //alert(from);
    switch (to.name) {
        case "main":
        case "conf":
            global_object.current_menu = "item-conf";
            global_object.bread_items = [].concat(bread_items_list["main"]);
            break;
        case "history":
            global_object.bread_items = [].concat(bread_items_list["history"]);
            global_object.current_menu = "item-history";
            break;
        case "room-info-ts":
            global_object.bread_items = [].concat(bread_items_list["room-info-ts"]);
            global_object.current_menu = "item-history";
            break;
        case "pass_codes":
            global_object.bread_items = [].concat(bread_items_list["pass_codes"]);
            global_object.current_menu = "pass_codes";
            break;
        case "faq":
            global_object.current_menu = "help";
            global_object.bread_items = [].concat(bread_items_list["faq"]);
            break;
        case "admin_monitoring":
            global_object.current_menu = "admin";
            global_object.bread_items = [].concat(bread_items_list["admin_monitoring"]);
            break;
        case "admin_rooms_history":
            global_object.current_menu = "admin";
            global_object.bread_items = [].concat(bread_items_list["admin_rooms_history"]);
            break;
        case "admin_monitoring_users":
            global_object.current_menu = "admin";
            global_object.bread_items = [].concat(bread_items_list["admin_monitoring_users"])
    }
    next();
})

export default {
    router,
    global_object: global_object
};
