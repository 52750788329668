<template>
  <div id="main-monitoring" style="padding:1rem; padding-top: 0rem;">
    <b-row align-h="between" class="justify-content-md-center" style="width: 100%; margin: 0; padding-top: 5rem">
      <b-col class="col-md-8 col-xl-8" style="width: 100%; padding: 0">
        <b-row style="text-align: center" align-h="center" class="m-0">
          <b-col cols="auto" class="p-1">
            <!--            <h1 style="font-size: 5rem">404</h1>-->
            <b-img src="/img/404.png" width="250%"/>
            <h2>Опаньки! Страница не найдена.</h2>
            <p>Похоже, запрашиваемая вами страница не найдена. Пожалуйста, попробуйте перейти на главную или может быть
              вам стоит поискать нужное у себя в профиле.
            </p>
            <b-button size="md" variant="primary" :to="{name:'main'}"
            >Перейти на главную страницу
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {}
}
</script>

<style scoped>

</style>
