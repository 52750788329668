<template>
  <div id="main-room-info" v-bind:class="getStyle()" style="">

    <div align-h="between" class="row justify-content-md-center" style="width: 100%; margin: 0px">
      <b-col class="" style="width: 100%; padding: 0px" v-bind:class="getRowStyle()">
        <b-overlay :show="flag_loading" rounded="sm">
          <b-tabs content-class="mt-3" v-if="info_is_loaded" active-nav-item-class="info-tab-link">
            <!--Локальные записи-->
            <b-tab title="Локальные записи" :active="l_tab=='local_recorders'" v-if="nonempty_local_records">
              <template #title>
                <b-spinner type="grow" small></b-spinner>
                Локальные записи
              </template>
              Список пользователей ведущил локальную запись в данный момент:
              <b-table striped hover style="text-align: center"
                       :items="local_record_info" :fields="fields_local_record_info">
                <template #cell(user_name)="data">
                  {{ data.item.user_name }}
                </template>
                <template #cell(start_ts)="data">
                  {{ dateFormat(new Date(Math.trunc(data.value * 1000)), "%d.%m.%Y %H:%M:%S", false) }}
                </template>
              </b-table>
            </b-tab>
            <!--Сводка-->
            <b-tab title="Сводка" :active="l_tab=='summary'">
              <b-row align-h="center" class="justify-content-md-center "
                     style="width: 100%; margin: 0px; overflow-wrap: break-word; ">
                <b-col cols="6" style="text-align: right"><p>Название комнаты:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_room_name_str }}</b></p></b-col>
              </b-row>
              <!--              <p>Название комнаты: <b>{{ desk_room_name_str }}</b></p><br/>-->
              <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
                <b-col cols="6" style="text-align: right"><p>Начало конференции:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_create_date }}</b></p></b-col>
              </b-row>
              <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
                <b-col cols="6" style="text-align: right"><p>Окончание конференции:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_end_date }}</b></p></b-col>
              </b-row>
              <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
                <b-col cols="6" style="text-align: right"><p>Длительность:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_duration }}</b></p></b-col>
              </b-row>
              <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
                <b-col cols="6" style="text-align: right"><p>Количество участников:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_number_of_users }}</b></p></b-col>
              </b-row>
              <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px"
                     v-if="desk_number_of_users_online>0">
                <b-col cols="6" style="text-align: right"><p>Участников online:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_number_of_users_online }}</b></p></b-col>
              </b-row>
              <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
                <b-col cols="6" style="text-align: right"><p>Сообщений в чате:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_number_of_messages }}</b></p></b-col>
              </b-row>
              <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
                <b-col cols="6" style="text-align: right"><p>Видео:</p></b-col>
                <b-col cols="6" style="text-align: left"><p><b>{{ desk_number_of_videos }}</b></p></b-col>
              </b-row>
            </b-tab>
            <b-tab title="Участники" :active="l_tab=='users'">
              <b-row align-h="end" style="width: 100%; padding: 0px; margin: 0px">
                <b-col cols="auto">
                  <b-button variant="primary" size="sm" class="mb-2"
                            @click="mailMessageToAll()">
                    <b-icon icon="envelope" aria-hidden="true" style="margin-right: 0px;"></b-icon>
                    <span v-if="!isMobile()" class="ml-1">Письмо всем участникам</span>
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button variant="primary" size="sm" class="mb-2"
                            @click="exportToCsv(desk_room_name_str,users,fields_users)">
                    <b-icon icon="download" aria-hidden="true" style="margin-right: 0px;"></b-icon>
                    <span v-if="!isMobile()" class="ml-1">Скачать список</span>

                  </b-button>
                </b-col>
              </b-row>
              <b-table striped hover v-bind:stacked="isMobile()" style="text-align: center" :items="users"
                       :fields="fields_users">
                <!-- A custom formatted column -->
                <template #cell(logo)="data">
                  <span style="text-align: center">
                  <b-avatar class="" size="sm" variant="info"
                            v-bind:src="getUserLogoURL(data.item.email,data.item.profile_pic)"/></span>
                </template>
                <template #cell(name)="data">
                  {{ data.value }}
                  <b-badge v-if="data.item.guest" variant="warning" pill size="md">Гость</b-badge>
                </template>
                <template #cell(create_ts)="data">
                  {{ dateFormat(new Date(Math.trunc(data.value * 1000)), "%d.%m.%Y %H:%M:%S", false) }}

                </template>
                <template #cell(duration)="data">
                  {{ data.item.duration }}
                  <b-badge v-if="data.item.online" variant="success" pill size="md">online</b-badge>
                  <b-progress height="0.2rem" :max="room_duration" class="m-0">
                    <b-progress-bar variant="primary"
                                    :value="(data.item.create_ts * 1000)-room_create_ts"></b-progress-bar>
                    <b-progress-bar variant="success"
                                    :value="data.item.end_ts_ms - (data.item.create_ts * 1000)"></b-progress-bar>
                    <b-progress-bar variant="primary" :value="room_end_ts - data.item.end_ts_ms"></b-progress-bar>
                  </b-progress>
                </template>
                <template #cell(email)="data">
                  <a v-bind:href="'mailto:' + data.value " target="_blank"
                     style="overflow-wrap: break-word;">{{ data.value }} </a>
                </template>
              </b-table>
            </b-tab>
            <b-tab title="Чат" :active="l_tab=='chat'">
              <div v-for="(item, index) in messages" v-bind:key="index">
                <b-row align-h="between" style="padding-bottom: 10px">
                  <b-col cols="6" style="text-align: left">
                    <b-avatar class="align-bottom" size="sm" variant="info"
                              v-bind:src="getUserLogoURL(item.user_email, item.user_profile_pic)"/>
                    <strong style="color: #004085"> {{ item.user_name }} </strong>
                  </b-col>
                  <b-col cols="6" style="text-align: right">
                    <small style="color: #8a8a8a">[{{
                        dateFormat(new Date(Math.trunc(item.create_ts * 1000)), "%d.%m.%Y %H:%M:%S", false)
                      }}]</small>
                  </b-col>
                </b-row>
                <!--          <p><strong style="color: #42b983"> {{ item.user_name }} </strong> <small style="color: #8a8a8a">[{{ dateFormat(new Date(Math.trunc(item.create_ts * 1000)), "%d.%m.%Y %H:%M:%S", false) }}]</small> </p>-->
                <p style="text-align: left; word-break: break-all;"> {{ item.body }}</p>
              </div>
            </b-tab>
            <b-tab title="Видео" v-if="videos!= null && videos.length>0" :active="l_tab=='videos'">
              <div class="row ">
                <div v-for="(item_video,item_index) in videos" :key="item_index"
                     class="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 " style="padding-bottom: 1rem">
                  <b-card
                      v-bind:sub-title="dateFormat(new Date(Math.trunc(item_video.record_ts * 1000)), '%d.%m.%Y %H:%M:%S', false)"
                      style="text-align: left">
                    <b-card-text>
                      <b-embed
                          type="iframe"
                          aspect="16by9"
                          v-bind:src="get_video_link(item_video)"
                          allowfullscreen
                      ></b-embed>
                    </b-card-text>
                    <a v-bind:href="get_video_link(item_video,'view')" target="_blank"
                       class="card-link">Ссылка на видео</a>
                  </b-card>
                </div>

              </div>
            </b-tab>
          </b-tabs>
          <p v-else>{{ data_message }}</p>
        </b-overlay>
      </b-col>
    </div>
  </div>
</template>

<script>
import md5 from "md5";

export default {

  props: {
    room_name: {
      type: String,
      default: null
    },
    time: {
      type: Number,
      default: null
    },
    modal: {
      type: Boolean,
      default: false
    },
    tab: {
      type: String,
      default: "summary"
      /**
       * "summary"
       * "users"
       * "chat"
       * "videos"
       */
    }
    // global_object: Object
  },
  name: "RoomInfo",
  data() {
    return {
      data_message: "",
      users: [],
      messages: [],
      videos: [
        {
          record_ts: "1634922453.0", room_name: "test",
          url: "https://drive.google.com/file/d/1d12OtrDdwQHP1o2BncAl4be4RvNF1q2_/preview",
          type: "gdrive"
        },
        {
          record_ts: "1634922453.0", room_name: "test",
          url: "https://drive.google.com/file/d/1d12OtrDdwQHP1o2BncAl4be4RvNF1q2_/preview",
          type: "gdrive"
        },
        {
          record_ts: "1634922453.0", room_name: "test",
          url: "https://drive.google.com/file/d/1d12OtrDdwQHP1o2BncAl4be4RvNF1q2_/preview",
          type: "gdrive"
        }
      ],
      flag_loading: true,
      l_tab: "summary",
      info_is_loaded: false,
      desk_room_name_str: "",
      desk_create_date: "",
      room_create_ts: Date.now(),
      room_end_ts: Date.now(),
      room_duration: 0,
      desk_end_date: "",

      desk_duration: "",
      desk_number_of_users: "",
      desk_number_of_messages: "",
      desk_number_of_videos: "",
      desk_number_of_users_online: "",
      fields_users: [
        {
          key: 'logo',
          label: "",
          sortable: false
        },
        {
          key: 'name',
          label: "Имя",
          sortable: true
        },
        {
          key: 'email',
          label: 'email',
          sortable: true
        },
        {
          key: 'create_ts',
          label: "Время входа",
          sortable: true
        },
        {
          key: 'duration',
          label: "Длительность",
          sortable: true
        }
      ],
      nonempty_local_records: false,
      video_conference_status: false,
      local_record_info: [],
      fields_local_record_info: [
        {key: 'user_name', label: "Имя"},
        {key: 'start_ts', label: "Время начала записи"}
      ]
    }
  },
  mounted() {
    md5('123');
    if (!this.modal) {
      this.$parent.api_check_auth();
    }
    let l_time = this.time
    if (!l_time) {
      l_time = this.$route.params.time;
      if (!l_time)
        l_time = Date.now() / 1000;

    }
    // if (!this.modal) {
    //   this.modal = false
    // }
    let l_room_name = this.room_name;
    if (!l_room_name) {
      l_room_name = this.$route.params.room_name;
      //alert("LROOMNAME= "+ l_room_name);
    }
    if (!this.modal) {
      this.$root.global_object.bread_items.push({
        text: l_room_name,
        active: true
      });
    }
    this.api_get_room_info(l_room_name, l_time);
    this.l_tab = this.$route.query.tab;
    //this.api_get_room_status(l_room_name);

  },
  methods: {
    getUserLogoURL(email, profile_pic = null) {
      if (profile_pic !== null && profile_pic.length > 0)
        return profile_pic;
      let resp_email = "";
      if (email === undefined)
        resp_email = "";
      else
        resp_email = email;
      return 'https://www.gravatar.com/avatar/' + md5(resp_email.trim().toLowerCase()) + '?d=identicon';
    },
    exportToCsv(filename, rows) {
      let df = this.dateFormat
      let replacer = function (key, value) {
        if (value === null)
          return ''
        if (key === "create_ts" || key === "end_ts") {
          return df(new Date(Math.trunc(value * 1000)), "%d.%m.%Y %H:%M:%S", false)
        } else
          return value;
      };
      const header = Object.keys(rows[0])
      let csv = [
        header.join(';'), // header row first
        //...rows.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'))
        ...rows.map(row => header.map(fieldName => replacer(fieldName, row[fieldName])).join(';'))
      ].join('\r\n');
      let blob = new Blob([csv], {type: 'text/csv;charset=utf-8,%EF%BB%BF;'});
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename + ".csv");
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv));
          link.setAttribute("download", filename + ".csv");
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    getRowStyle() {
      if (this.modal)
        return "";
      else
        return "col-md-8 col-xl-8";
    },
    getStyle() {
      if (this.modal)
        return "";
      else
        return "not_modal_style";
    },
    isMobile() {
      let value = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i);
      if (value == null) {
        return false;
      }
      return true;
    },

    //TODO: Функция для преобразования текста в ссылки. Пока не используем, опасно, нужно проверить.
    // Linkify(inputText) {
    //   //URLs starting with http://, https://, or ftp://
    //   var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    //   var replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');
    //
    //   //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    //   var replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    //   replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');
    //
    //   //Change email addresses to mailto:: links
    //   var replacePattern3 = /(([a-zA-Z0-9_\-.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;
    //   replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
    //
    //   return replacedText
    // },
    get_video_link(item, to = "preview") {
      if (item.type === "gdrive") {
        if (to === "preview") {
          return item.url + "/preview";
        }
        if (to === "view") {
          return item.url + "/view";
        }
      }
      return item.url;
    },
    calc_users_online(users) {
      let count = 0;
      for (let user of users) {
        if (user.end_ts === "" || user.end_ts == null) {
          count++;
        }
      }
      return count
    },
    get_duration_string(start_ts, end_ts) {
      let end_date = null;
      let is_online = false
      if (end_ts === "" || end_ts == null) {
        // alert("NULL");
        is_online = true;
        end_date = this.room_end_ts;
        //return ["online", true];
      } else
        end_date = (new Date(Math.trunc(end_ts * 1000))).getTime();
      let start_date = (new Date(Math.trunc(start_ts * 1000))).getTime();
      let dur_sec = Math.trunc((end_date - start_date) / 1000);
      let seconds = dur_sec % 60; // Получаем секунды
      let minutes = dur_sec / 60 % 60; // Получаем минуты
      let hour = dur_sec / 60 / 60; // Получаем часы
      // Создаём строку с выводом времени
      let str_duration = `${Math.trunc(hour)}:${Math.trunc(minutes).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}:${Math.trunc(seconds).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}`;
      return [str_duration, is_online, end_date];

    },
    dateFormat: function (date, fstr, utc) {
      utc = utc ? 'getUTC' : 'get';
      return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
          case '%Y':
            return date[utc + 'FullYear'](); // no leading zeros required
          case '%m':
            m = 1 + date[utc + 'Month']();
            break;
          case '%d':
            m = date[utc + 'Date']();
            break;
          case '%H':
            m = date[utc + 'Hours']();
            break;
          case '%M':
            m = date[utc + 'Minutes']();
            break;
          case '%S':
            m = date[utc + 'Seconds']();
            break;
          default:
            return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
      });
    },
    mailMessageToAll() {
      if (this.users.length === 0)
        return;
      let string_emails = "";
      let dictionary_emails = this.users.reduce((a, x) => ({...a, [x.email]: x.email}), {});
      for (let key in dictionary_emails) {
        string_emails += key + ",";
      }
      // alert(JSON.stringify(string_emails));
      // for (let user of this.users){
      //
      // }
      // this.users
      let uri = "mailto:" + string_emails;
      uri += "?subject=";
      uri += encodeURIComponent("Сообщение для всех участников встречи: " + this.desk_room_name_str + " от " + this.desk_create_date);
      uri += "&body=";
      uri += encodeURIComponent("");
      this.$root.global_object.go_link_blank(uri);
    },
    api_get_room_info(room_name, ts) {
      this.axios
          .get('/api/v1/get_room_info?room_name=' + room_name + '&ts=' + ts, {
            withCredentials: true
          })
          .then(response => {
            //"suc room info");
            //alert(JSON.stringify(response.data));
            if (response.data.description) {
              let description = response.data.description;

              this.desk_room_name_str = description.room_name;
              this.desk_number_of_users = description.number_of_users;

              this.desk_number_of_messages = description.number_of_messages;
              let seconds = description.duration % 60; // Получаем секунды
              let minutes = description.duration / 60 % 60; // Получаем минуты
              let hour = description.duration / 60 / 60; // Получаем часы
              // Создаём строку с выводом времени
              this.desk_duration = `${Math.trunc(hour)}:${Math.trunc(minutes).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}:${Math.trunc(seconds).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}`;
              this.desk_create_date = this.dateFormat(new Date(Math.trunc(description.create_ts * 1000)), "%d.%m.%Y %H:%M:%S", false)
              this.room_create_ts = Math.trunc(description.create_ts * 1000);

              //(new Date(Math.trunc(description.create_ts * 1000))).toLocaleDateString("ru-RU");
              if (description.end_ts === null) {
                this.api_get_room_status(room_name);
                this.desk_end_date = "нет";
                this.room_end_ts = Date.now();
              } else {
                this.desk_end_date = this.dateFormat(new Date(Math.trunc(description.end_ts * 1000)), "%d.%m.%Y %H:%M:%S", false);
                this.room_end_ts = Math.trunc(description.end_ts * 1000);
              }
              this.room_duration = this.room_end_ts - this.room_create_ts;


              this.users = response.data.users;

              for (let user of this.users) {
                [user.duration, user.online, user.end_ts_ms] = this.get_duration_string(user.create_ts, user.end_ts)
              }
              this.desk_number_of_users_online = this.calc_users_online(this.users);
              this.messages = response.data.messages;
              this.videos = response.data.videos;
              if (this.videos) {
                this.desk_number_of_videos = this.videos.length
              } else this.desk_number_of_videos = 0;
              this.info_is_loaded = true;
              this.flag_loading = false;
            } else {
              this.data_message = "Данные отсутствуют";
              this.info_is_loaded = false;
              this.flag_loading = false;
            }
          })
          .catch((error) => {
            this.info_is_loaded = false;
            this.data_message = "Данные отсутствуют";
            this.flag_loading = false;
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
      //this.flag_loading = false;
    },
    api_get_room_status(room_name) {
      this.axios
          .get('/api/v1/get_room_status?room_name=' + room_name,
              {withCredentials: true})
          .then(response => {
            if (response.data.status == 1) {
              this.video_conference_status = true;

              if (response.data.local_records.length) {
                this.nonempty_local_records = true;
                this.local_record_info = response.data.local_records;
              } else {
                this.nonempty_local_records = false;
              }

            } else {
              this.video_conference_status = false;
            }
          })
          .catch((error) => {
            console.log(error);
          })
    },

  }
}


</script>
<style>
.info-tab-link {
  background-color: #fff !important;
}

.nav-tabs .nav-link {
  background-color: #eeeff4 !important;
  border-top-color: rgb(222, 226, 230) !important;
  border-right-color: rgb(222, 226, 230) !important;
  border-bottom-color: rgb(255, 255, 255) !important;
  border-left-color: rgb(222, 226, 230) !important;
  /*border: 1px !important;*/
  /*border-color: #2c3e50 !important;*/
}

.nav-tabs .nav-link:hover {
  background-color: #b8daff !important;
  border-top-color: rgb(222, 226, 230) !important;
  border-right-color: rgb(222, 226, 230) !important;
  border-bottom-color: rgb(255, 255, 255) !important;
  border-left-color: rgb(222, 226, 230) !important;

  /*border: 1px !important;*/
  /*border-color: #2c3e50 !important;*/
}

.nav-tabs .nav-link.active {
  background-color: #004085 !important;
  color: white !important;
  /*border: 1px !important;*/
  /*border-color: #2c3e50 !important;*/
}

</style>

<style scoped>

.not_modal_style {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}
</style>
