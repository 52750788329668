<template>
  <div id="main-FAQ" style="padding:1rem; padding-top: 0rem;">
    <b-row align-h="between" class="justify-content-md-center" style="width: 100%; margin: 0px">
      <b-col class="col-md-8 col-xl-8" style="width: 100%; padding: 0px">
        <div>

          <b-card title="Часто задаваемые вопросы" img-src="/img/header-questions.jpg" img-top tag="article"
            style="text-align:left" class="mb-2 p-0">

            <b-card-text>
              <div><a href="#">Как создать комнату</a></div>
              <div><a href="#auto-service">Авторизация в сервисе</a></div>
              <div><a href="#use-app">Использование мобильного приложения</a></div>
              <div><a href="#invite">Приглашение внешних участников</a></div>
              <div><a href="#use-local">Использование облачной записи в конференции</a></div>
              <br />
              <div>
                <h4 class="card-yitle">Как создать комнату</h4>
                <p>
                  Вы можете создать собственную виртуальную комнату.
                  Для этого откройте <a href="/">Главную страницу</a>, авторизуйтесь и введите в окно придуманное вами
                  название комнаты:
                </p>
                <p style="max-width: 30rem; max-height: 30rem; display:block; margin: auto; margin-bottom: 1rem;">
                  <expandable-image src="/img/create_conf.jpg" fluid alt="create room" style="" />
                </p>

                <p>Далее нажмите "Подключиться".</p>
              </div>

            </b-card-text>
          </b-card>
          <a name="auto-service"></a>
          <b-card title="Авторизация в сервисе" img-src="/img/header-keys.jpg" img-alt="Image" img-top tag="article"
            style="text-align:left" class="mb-2 p-0">
            <b-card-text>
              <b-row>
                <b-col>
                  <div>
                    Для авторизации в приложении понадобиться зайти в <b>Профиль</b> и нажать на кнопку <b>Войти</b>.
                  </div>
                </b-col>
              </b-row>
              <br />
              <b-row>
                <span v-if="show_card1" style="padding-bottom: 1rem;margin-left: 1rem;">
                  <b-row>
                    <b-col>
                      <p style="max-width: 30rem; max-height: 30rem; display:block; margin: auto; margin-bottom: 1rem;">
                        <expandable-image src="/img/enter.png" fluid alt="enter" style="" />
                      </p>

                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div>
                        Далее Вам будет представлен выбор как зайти в приложение: с помощью аккаунта с <b>миэмовской почты
                          (miem.hse.ru)</b>
                        или с помощью аккаунта в <b>портале ЕЛК ВШЭ (edu.hse.ru и hse.ru)</b>.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <p style="max-width: 30rem; max-height: 30rem; display:block; margin: auto; margin-bottom: 1rem;">
                        <expandable-image src="/img/change.png" fluid alt="change" style="" />
                      </p>
                      
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        При выборе <b>Google</b> появится соответствующее окно.
                        При желании можно изменить аккаунт.
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p style="max-width: 30rem; max-height: 30rem; display:block; margin: auto; margin-bottom: 2rem;">
                        <expandable-image src="/img/gmail.png" fluid alt="gmail" style="" />
                      </p>
                      
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div>
                        При выборе <b>ЕЛК ВШЭ</b> вход происходит через единую систему авторизации "Высшей школы
                        экономики".
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <p style="max-width: 30rem; max-height: 30rem; display:block; margin: auto; margin-bottom: 1rem;">
                        <expandable-image src="/img/lms.png" fluid alt="elk" style="" />
                      </p>

                    </b-col>
                  </b-row>
                </span>
              </b-row>
              <b-row>
                <b-col>
                  <b-button href="#" size="sm" variant="primary" @click="() => { this.show_card1 = !this.show_card1 }">
                    {{ this.show_card1 ? "Скрыть" : "Подробнее" }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
          <a name="use-app"></a>
          <b-card title="Использование мобильного приложения" img-src="/img/header-phones.jpg" img-alt="Image" img-top
            tag="article" style="text-align:left" class="mb-2 p-0">
            <b-card-text>
              <b-row>
                <span v-if="show_card2" style="width: 100%; padding-bottom: 1rem;margin-left: 1rem;">
                  <b-row>
                    <b-col>
                      <div>
                        При входе с мобильной версии приложения есть выбор - подключиться
                        через <b>браузер</b> или <b>приложение</b>.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <p style="max-width: 30rem; max-height: 30rem; display:block; margin: auto; margin-bottom: 1rem;">
                        <expandable-image src="/img/app.png" fluid alt="mobile app" style="" />
                      </p>

                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        Если у Вас еще не скачено приложение, это можно сделать в
                        <a type="button" class="btn-floating cyan"
                          href="https://play.google.com/store/apps/details?id=org.jitsi.meet&hl=en&gl=US"><i
                            class="fab fa-android" aria-hidden="true"></i><b>Play Маркете</b></a>
                        или <a type="button" class="btn-floating cyan"
                          href="https://apps.apple.com/us/app/jitsi-meet/id1165103905"><i class="fab fa-apple"
                            aria-hidden="true"></i><b>AppStore</b></a>.
                      </div>
                    </b-col>
                  </b-row>
                </span>
              </b-row>
              <b-row>
                <b-col>
                  <b-button href="#" size="sm" variant="primary" @click="hide_card2">
                    {{ this.show_card2 ? "Скрыть" : "Подробнее" }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>

          </b-card>
          <a name="invite"></a>
          <b-card title="Приглашение внешних участников" img-src="/img/header-invite.jpg" img-alt="Image" img-top
            tag="article" style="max-width: 100rem;text-align:left" class="mb-2 p-0">
            <b-card-text>
              <b-row>
                <span v-if="show_card3" style="padding-bottom: 1rem;margin-left: 1rem;">
                  <b-row>
                    <b-col>
                      <div>
                        В конференции есть кнопка <b>Профиль</b>, которая предоставляет дополнительные возможности
                        участникам. Одна из возможностей - пригласить участника. Для этого надо нажать на кнопку
                        <b>пригласить участника</b>.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/profile.png" style="width: 30%;height: 90%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        При нажатии на кнопку появляется модальное окно с выбором: можно поделиться ссылкой
                        на конференцию, скопировать ссылку или переслать по почте.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/invite_window.png" style="width: 40%;height: 100%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        При выборе <b>поделиться</b> появляются мессенжеры, в которые можно кинуть ссылку.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/share.png" style="width: 30%;height: 90%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div>
                        При нажатии на кнопку <b>копировать</b> происходить копирование ссылки.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/copy.png" style="width: 40%;height: 100%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      При нажатии на кнопку с изображением почты появляется модальное окно с выбором почты получателя.
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/mails.png" style="width: 40%;height: 100%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        В <b>Профиле</b> есть кнопка <b>Пропуска для гостей</b>. В этом раздела можно создать пропуск на
                        конференцию для посторонних участников.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/code.png" style="width: 30%;height: 90%;display:block;
            margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        В правой части страницы находится кнопка <b>создать пропуск</b>.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/create.png" style="width: 40%;height: 100%;display:block;
                    margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        При нажатии на кнопку появляется модальное окно, заполнив которое, можно сделать пропуск.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/creater.png" style="width: 40%;height: 100%;display:block;
                            margin:auto;">
                    </b-col>
                  </b-row>
                </span>
              </b-row>
              <b-row>
                <b-col>
                  <b-button href="#" size="sm" variant="primary" @click="hide_card3">
                    {{ this.show_card3 ? "Скрыть" : "Подробнее" }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
          <a name="use-local"></a>
          <b-card title="Использование записи в конференциях" img-src="/img/header-rec.jpg" img-alt="Image" img-top
            tag="article" style="max-width: 100rem;text-align:left" class="mb-2 p-0">
            <b-card-text>
              <b-row>
                <span v-if="show_card4" style="padding-bottom: 1rem;margin-left: 1rem;">
                  <b-row>
                    <b-col>
                      <div>
                        Еще одной из возможностей нашего приложения является <b>облачная запись</b>.
                        В <b>Профиле</b> есть кнопка <b>начать облачную запись</b>
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/start_rec_win.png" style="width: 30%;height: 90%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        Появляется модальное окно, в котором уточняется, действительно ли пользователь хочет начать
                        запись.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/window_loc_rec.png" style="width: 40%;height: 100%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        В <b>Профиле</b> название кнопки <b>начать облачную запись</b> меняется на <b>остановить облачную
                          запись</b>.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/stop_recording.png" style="width: 30%;height: 90%;display:block;
margin:auto;">
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div>
                        Кроме <b>облачной записи</b> есть возможность записывать конференцию локально.
                        В <b>Профиле</b> есть кнопка <b>начать локальную запись</b>
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/start_recording.png" style="width: 30%;height: 90%;display:block;
            margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        Появляется модальное окно, в котором уточняется, действительно ли пользователь хочет начать
                        запись.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/window_recording.png" style="width: 40%;height: 100%;display:block;
            margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        Появится окно, которое предоставит выбор окна для записи.
                        Для доступа к потоку аудио необходимо обязательно поставить галочку
                        на пункте <b>"Поделиться системным аудио"</b>.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>

                      <img src="/img/choose_table.png" style="width: 50%;height: 100%;display:block;
            margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        В <b>Профиле</b> название кнопки <b>начать локальную запись</b> меняется на <b>остановить
                          локальную запись</b>.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/stop_loc_rec.png" style="width: 30%;height: 90%;display:block;
            margin:auto;">
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <div>
                        В <b>Загрузке</b> появится файл с записью конференции.
                      </div>
                    </b-col>
                  </b-row>
                  <br />
                  <b-row>
                    <b-col>
                      <img src="/img/result.png" style="width: 30%;height: 90%;display:block;
                                    margin:auto;">
                    </b-col>
                  </b-row>
                </span>
              </b-row>
              <b-row>
                <b-col>
                  <b-button href="#" size="sm" variant="primary" @click="hide_card4">
                    {{ this.show_card4 ? "Скрыть" : "Подробнее" }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>


        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      show_card1: false,
      show_card2: false,
      show_card3: false,
      show_card4: false
    }
  },
  mounted() {

  },
  methods: {
    hide_card1() {
      this.show_card1 = !this.show_card1
    },
    hide_card2() {
      this.show_card2 = !this.show_card2
    },
    hide_card3() {
      this.show_card3 = !this.show_card3
    },
    hide_card4() {
      this.show_card4 = !this.show_card4
    }
  }
}
</script>

<style scoped></style>
