<!--
 * @ Author: Victor Minchenkov
 * @ Create Time: 2021-09-14 12:20:08
 * @ Modified by: Victor Minchenkov
 * @ Modified time: 2024-02-07 21:00:50
 * @ Description: Copyright 2024, Victor Minchenkov
 -->

<template>
  <div id="app" style="width: 100%; height: 100%; min-height: 100%; background-color: #eeeff4;
         ">
    <div id="layer1" v-if="top_button_visible">
      <b-button @click="go_to_top" variant="outline-secondary" v-b-popover.hover.auto="'Наверх'">
        <b-icon icon="caret-up" aria-hidden="true"></b-icon>
      </b-button>
    </div>
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <!-- Модальное окно для подробной информации о текущей встрече -->
    <b-modal id="modal-room-info" title="Информация о встрече" centered scrollable ok-only
             ok-title="Закрыть" size="xl" footer-class="p-2">
      <RoomInfo v-bind:room_name="room_text" v-bind:modal="true" v-bind:time="null"></RoomInfo>
    </b-modal>
    <!-- Модальное окно для создания пропуска -->
    <b-modal id="modal-create-pass-code" size="xl" button-size="sm" title="Создать пропуск для гостей" centered
             overflowing
             ok-only
             ok-title="Закрыть" footer-class="p-2">
      <PassCodeEditor v-on:updateEvent="eventPassCodeEditor" v-bind:current_room_name="this.room_text"/>
    </b-modal>
    <!-- Модальное окно для просмотра списка пропусков -->
    <b-modal id="modal-view-pass-codes" size="xl" button-size="sm" title="Мои пропуска" centered overflowing ok-only
             ok-title="Закрыть" footer-class="p-2">
      <PassCodes v-bind:modal="true" v-bind:filter_room_name="this.room_text" v-on:updateEvent="eventPassCodeEditor"/>
    </b-modal>
    <!-- Модальное окно для приглашения участников -->
    <b-modal id="modal-invite" title="Пригласить участников" centered ok-only
             ok-title="Закрыть" footer-class="p-2">
      <p class="my-2">
        Поделитесь ссылкой на встречу, чтобы пригласить других участников:
      </p>
      <b-row align-h="between">
        <b-col cols="6">
          <b-form-textarea id="full-link-plaintext" ref="full-link-plaintext" plaintext
                           style="background-color: #eeeff4; padding: 3px; border-radius: 3px;"
                           :value="this.room_full_link"></b-form-textarea>
        </b-col>
        <b-col cols="6">
          <b-row align-h="start" class="mb-3">
            <b-col cols="auto" md="2" class="p-1">
              <b-button variant="primary" size="sm" @click="shareAsCopyLink()"
                        v-b-popover.hover.top="'Копировать ссылку'">
                <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
              </b-button>
            </b-col>
            <b-col cols="auto" md="2" class="p-1">
              <b-button variant="primary" size="sm" @click="shareAsShareLinkTo()" v-b-popover.hover.top="'Поделиться'">
                <b-icon icon="share" aria-hidden="true"></b-icon>
              </b-button>
            </b-col>
            <b-col cols="auto" md="2" class="p-1">
              <b-button variant="primary" size="sm" @click="shareAsMailLinkTo()" v-b-popover.hover.top="'Письмо'">
                <b-icon icon="envelope" aria-hidden="true"></b-icon>
              </b-button>
            </b-col>
            <b-col cols="auto" md="2" class="p-1">
              <b-dropdown size="sm" variant="primary" id="dropdown-calendar" class="mr-1"
                          v-b-popover.hover.top="'Добавить в календарь'" no-caret>
                <template #button-content>
                  <b-icon icon="calendar3" aria-hidden="true"></b-icon>
                </template>
                <b-dropdown-item-button @click="shareAsYandexCalendarLink"><span style="color: #f33">Я</span>ндекс
                  календарь
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="shareAsHseCalendarLink"><span style="color: #0078d4">ВШЭ</span>
                  календарь
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="shareAsGoogleCalendarLink"><span style="color: #4285f4">G</span>oogle
                  календарь
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="shareAsOutlookCalendarLink"><span style="color: #0078d4">O</span>utlook
                  календарь
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="shareAsICSFile">ICS файл</b-dropdown-item-button>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr align="center" width="100%" size="1"/>
      <p class="my-2">
        Для приглашения участников, не имеющих постоянных аккаунтов, воспользуйтесь системой пропусков:
      </p>
      <b-row cols="12" align-h="between" class="justify-content-md-center">
        <b-col sm="5" md="5" lg="5" v-if="is_auth()" style="padding-bottom: 1rem">
          <b-button variant="primary" size="sm" @click="()=>{this.showModalCreatePassCode()}"
          >
            <b-icon icon="plus" aria-hidden="true"></b-icon>
            Создать пропуск
          </b-button>
        </b-col>
        <b-col sm="5" md="5" lg="5" v-if="is_auth() && !is_conf_now" style="padding-bottom: 1rem">
          <b-button variant="primary" size="sm"
                    @click="$bvModal.hide('modal-invite'); goToRoute({name: 'pass_codes',query:{filter_room_name: room_text}})"
          >
            <b-icon icon="journal" aria-hidden="true"></b-icon>
            Готовые пропуска
          </b-button>
        </b-col>
        <b-col sm="5" md="5" lg="5" v-if="is_auth() && is_conf_now">
          <b-button variant="primary" size="sm" @click="$bvModal.hide('modal-invite'); showModalViewPassCodes()"
          >
            <b-icon icon="journal" aria-hidden="true"></b-icon>
            Готовые пропуска
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Модальное окно для локальной записи -->
    <b-modal id="bv-modal-record" title="Локальная запись конференции" centered ok-title="Начать запись"
             cancel-title="Отмена"
             @ok="record_start">
      <p class="my-2">
        Локальная запись осуществляется средствами вашего браузера и хранится только локально.
      </p>
      <p>
        После начала записи необходимо выбрать текущую вкладку или окно содержимое которого будет записано.</p>
      <p>
        <strong>Для корректной записи аудио потока в некоторых браузерах необходимо дополнительно установить галочку на
          пункте «Предоставить доступ к аудио».</strong>
      </p>
      <p> После окончания записи видео файл будет автоматически сохранен на ваш локальный диск.
      </p>
    </b-modal>
    <!-- Модальное окно для облачной записи -->
    <b-modal id="bv-modal-cloud-record" title="Облачная запись конференции" centered ok-title="Начать запись"
             cancel-title="Отмена"
             @ok="cloud_record_start">
      <p class="my-2">
        Облачная запись осуществляется средствами облачных рекрдеров и хранится на сервере.
      </p>
      <p>
        После окончания записи видео файл будет обработан и ссылку на него cмогут найти все участники встречи и разделе
        <strong>"История"</strong>.
      </p>
    </b-modal>
    <!-- Ссылка на страницу помощи -->
    <div class="help-link-div" v-if="!is_conf_now" ref="help-link-div" style="font-size: 1rem; padding-right: 1rem">
      
      <b-link target="_blank"  href="https://docs.google.com/document/d/11TgaLLTxrQTcStSOg_Ivu6125za9ctJ6tu3IMxT9lNA/edit#heading=h.vu2j2omzzlvc">
            Помощь
      </b-link>
    </div>
    <!-- Кнопка профиля -->
    <div class="menu-button-div" ref="main-menu-button-div" style="font-size: 3rem; padding-left: 1rem">
      <b-row cols="10" class="p-0 m-0">
        <b-col class="p-0" style="line-height: 0.5;">
          <b-button v-b-toggle.sidebar-1 variant="primary" size="sm" class="mb-2">
            <b-icon icon="list" aria-hidden="true" v-if="!is_auth_flag"></b-icon>
            <b-avatar class="" size="sm" v-bind:text="user_shortname"
                      v-if="is_auth_flag && !user_has_picture "></b-avatar>
            <b-avatar class="" size="sm" variant="info" v-bind:src="user_picture"
                      v-if="is_auth_flag && user_has_picture"></b-avatar>
            <span class="pl-1" v-if="!is_conf_now">Профиль</span>
          </b-button>
          <b-button variant="primary" v-b-popover.hover.right="'Остановить локальную запись'" size="sm" class="mb-2"
                    @click="record_stop()" style="margin-left: 0.5rem; background: none"
                    v-if="conf_states.is_local_record_now">
            <b-icon icon="stop-btn" aria-hidden="true" style="color: red"></b-icon>
          </b-button>
          <b-button v-b-modal.modal-room-info variant="warning"
                    v-b-popover.hover.right="'Участники ведут локальную запись'"
                    size="sm" class="mb-2" style="margin-left: 0.5rem; background: none"
                    v-if="nonempty_local_records">
            <b-icon icon="exclamation-circle" aria-hidden="true" style="color: yellow"></b-icon>
          </b-button>
        </b-col>

      </b-row>
      <!--      <b-button pill variant="outline-primary" size="sm" class="mb-2" style="margin-left: 10px; background-color: white"-->
      <!--                disabled v-if="this.is_conf_now">{{ this.timer_str }}-->
      <!--      </b-button>-->
    </div>

    <!-- Боковое меню -->
    <b-sidebar id="sidebar-1" ref="profileSidebar" title="Профиль" backdrop shadow backdrop-variant='transparent'>
      <div class="px-3 py-2">
        <b-list-group>
          <b-list-group-item class="d-flex align-items-center" v-if="is_auth()" style="border-radius: 0.25rem">
            <b-avatar class="mr-3" v-bind:text="user_shortname" v-if="!user_has_picture"
                      @click="go_link_blank('https://lk.hse.ru/profile')" href="#"></b-avatar>
            <!--            <b-avatar class="mr-3" variant="info" v-bind:src="user_picture" v-if="user_has_picture"-->
            <!--                      @click="go_link_blank(user_gravatar_profile)" href="#"></b-avatar>-->
            <b-avatar class="mr-3" variant="info" v-bind:src="user_picture" v-if="user_has_picture"
                      @click="go_link_blank('https://lk.hse.ru/profile')" href="#"></b-avatar>
            <span class="mr-auto" style="text-align: left; font-size: 0.8rem;"><b>{{ this.user_name }}</b></span>
            <!--          <b-badge>5</b-badge>-->
            <b-dropdown icon="arrow-bar-down" variant="outline-default" id="dropdown-1" class="mr-1">
              <b-dropdown-item @click="go_link_blank(url_profile)">Профиль безопасности</b-dropdown-item>
              <!--              <b-dropdown-item @click="go_link_blank(user_gravatar_profile)">Профиль Gravatar</b-dropdown-item>-->
              <b-dropdown-item @click="go_link_blank('https://lk.hse.ru/profile')">Профиль ВШЭ</b-dropdown-item>
              <b-dropdown-item @click="go_link_logout(url_logout)">Выход</b-dropdown-item>
            </b-dropdown>

          </b-list-group-item>
          <b-list-group-item class="align-items-center" v-if="!is_auth()" style="border-radius: 0.25rem">
            <b-button variant="primary" style="width: 100%" @click="login()">Войти</b-button>
          </b-list-group-item>
        </b-list-group>
        <br/>
        <b-list-group>
          <b-card no-body align="left"
                  header-tag="header"
                  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab" header-class="item-header">
              <b-button-group style="width: 100%">
                <b-list-group-item class="menu-item-in-group-first" id="item-conf" block v-b-toggle.conference
                                   variant="primary"
                                   :to="{name:'main', params: { id: '' }}"
                                   v-bind:active="this.$root.global_object.current_menu == 'item-conf'">
                  Конференция
                </b-list-group-item>
                <b-navbar-toggle class="menu-item-in-group-second" target="conference"
                                 style="padding: 12px; color: #004085; background-color: #eeeff4;">
                  <template #default="{ expanded }">
                    <!--                    <b-button size="sm" style="color: #004085; background-color: inherit; border-style: none; padding: 12px;" >-->
                    <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                    <b-icon v-else icon="chevron-bar-down"></b-icon>
                    <!--                    </b-button>-->
                  </template>
                </b-navbar-toggle>
              </b-button-group>
            </b-card-header>
            <b-collapse class="md-2" align="left" id="conference"
                        v-bind:visible="this.$root.global_object.current_menu == 'item-conf'" accordion="my-accordion"
                        role="tabpanel">
              <b-card-body>
                <b-card-title>{{ room_text }}</b-card-title>
                <b-card-text v-if="this.is_conf_now">Текущий сеанс: {{ this.timer_str }}</b-card-text>
                <b-button id="menu-addUsers-button" v-b-modal.modal-invite block variant="primary" size="sm"
                          class="mb-2" @click="showModalInvite()" v-if="is_auth()">
                  <b-icon icon="person-plus" aria-hidden="true" style="margin-right: 10px;"></b-icon>
                  Пригласить участников
                </b-button>
                <b-button v-b-modal.modal-room-info block variant="primary" size="sm" class="mb-2" v-if="is_conf_now">
                  <b-icon icon="info-circle" aria-hidden="true" style="margin-right: 10px;"></b-icon>
                  Подробнее
                </b-button>
                <b-button id="menu-cloudRecord-button" block variant="primary" size="sm"
                          class="mb-2" v-b-modal.bv-modal-cloud-record
                          v-if="is_conf_now && !conf_states.is_cloud_record_now && conf_states.current_role==='moderator'">
                  <b-icon icon="record-fill" aria-hidden="true" style="margin-right: 10px; color: red"></b-icon>
                  Облачная запись
                </b-button>
                <b-button block variant="primary" size="sm" class="mb-2" @click="cloud_record_stop()"
                          v-if="is_conf_now && conf_states.is_cloud_record_now && conf_states.current_role==='moderator'">
                  <b-icon icon="stop-btn" aria-hidden="true" style="margin-right: 10px; color: red"></b-icon>
                  Остановить запись в облако
                </b-button>
                <b-button id="show-btn" block variant="primary" v-b-modal.bv-modal-record size="sm" class="mb-2"

                          v-if="!isMobile() && is_conf_now && !conf_states.is_local_record_now">
                  <b-icon icon="record-fill" aria-hidden="true" style="margin-right: 10px; color: red"></b-icon>
                  Локальная запись
                </b-button>

                <b-button block variant="primary" size="sm" class="mb-2" @click="record_stop()"
                          v-if="conf_states.is_local_record_now">
                  <b-icon icon="stop-btn" aria-hidden="true" style="margin-right: 10px; color: red"></b-icon>
                  Остановить локальную запись
                </b-button>
              </b-card-body>
            </b-collapse>

          </b-card>
          <b-card no-body align="left"
                  header-tag="header"
                  class="mb-1"
                  v-if="is_auth()">
            <b-card-header header-tag="header" class="p-1" role="tab" header-class="item-header">
              <b-list-group-item class="menu-item" id="item-history" block variant="primary" :to="{name:'history'}"

                                 v-bind:active="this.$root.global_object.current_menu == 'item-history'">
                История
              </b-list-group-item>
            </b-card-header>

          </b-card>
          <b-card no-body align="left"
                  header-tag="header"
                  class="mb-1"
                  v-if="is_auth()">
            <b-card-header header-tag="header" class="p-1" role="tab" header-class="item-header">
              <b-list-group-item class="menu-item" id="item-passes" block variant="primary" :to="{name:'pass_codes'}"
                                 v-bind:active="this.$root.global_object.current_menu == 'pass_codes'">
                Пропуска для гостей
              </b-list-group-item>
            </b-card-header>

          </b-card>
          <b-card no-body align="left"
                  header-tag="header"
                  class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab" header-class="item-header">
              <b-button-group style="width: 100%">
                <b-list-group-item class="menu-item-in-group-first" id="item-help" block v-b-toggle.help
                                   variant="primary"
                                   href="#"
                                   v-bind:active="this.$root.global_object.current_menu == 'help'"
                >
                  Помощь
                </b-list-group-item>
                <b-navbar-toggle class="menu-item-in-group-second" target="help"
                                 style="padding: 12px; color: #004085; background-color: #eeeff4;">
                  <template #default="{ expanded }">
                    <!--                    <b-button size="sm" style="color: #004085; background-color: inherit; border-style: none; padding: 12px;" >-->
                    <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                    <b-icon v-else icon="chevron-bar-down"></b-icon>
                    <!--                    </b-button>-->
                  </template>
                </b-navbar-toggle>
              </b-button-group>
            </b-card-header>
            <b-collapse class="md-2" align="left" id="help"
                        v-bind:visible="this.$root.global_object.current_menu == 'help'" accordion="my-accordion-2"
                        role="tabpanel">
              <b-card-body>
                <b-link href="#" class="card-link" :to="{name:'faq'}">
                  <b-icon icon="question-circle" aria-hidden="true" style="margin-right: 10px;"></b-icon>
                  FAQ
                </b-link>
                <br/>
                <b-link href="#" class="card-link" @click="showModalFeedback()">
                  <b-icon icon="life-preserver" aria-hidden="true" style="margin-right: 10px;"></b-icon>
                  Форма обратной связи
                </b-link>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body align="left"
                  header-tag="header"
                  class="mb-1"
                  v-if="user_is_admin"
          >
            <b-card-header header-tag="header" class="p-1" role="tab" header-class="item-header">
              <b-button-group style="width: 100%">
                <b-list-group-item class="menu-item-in-group-first" id="item-admin" block v-b-toggle.admin
                                   variant="primary"
                                   href="#"
                                   v-bind:active="this.$root.global_object.current_menu == 'admin'"
                >
                  Администрирование
                </b-list-group-item>
                <b-navbar-toggle class="menu-item-in-group-second" target="admin"
                                 style="padding: 12px; color: #004085; background-color: #eeeff4;">
                  <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                    <b-icon v-else icon="chevron-bar-down"></b-icon>
                  </template>
                </b-navbar-toggle>
              </b-button-group>
            </b-card-header>
            <b-collapse class="md-2" align="left" id="admin"
                        v-bind:visible="this.$root.global_object.current_menu == 'admin'" accordion="my-accordion-3"
                        role="tabpanel">
              <b-card-body>
                <b-link class="card-link" :to="{name:'admin_monitoring'}">
                  <b-icon icon="stopwatch" aria-hidden="true" style="margin-right: 10px;"></b-icon>
                  Мониторинг
                </b-link>
                <br/>
                <b-link class="card-link"
                        :to="{name:'admin_rooms_history', query: {by_room: 'true', only_online: 'false'}}">
                  <b-icon icon="book" aria-hidden="true" style="margin-right: 10px;"></b-icon>
                  История комнат
                </b-link>
                <br/>
                <b-link class="card-link" :to="{name:'admin_monitoring_users'}">
                  <b-icon icon="people" aria-hidden="true" style="margin-right: 10px"></b-icon>
                  Пользователи
                </b-link>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-list-group>


      </div>
      <!--      Footer-->
      <template #footer="{ }">
        <div class="d-flex align-items-center  px-3 py-2"
             style="background-color: #eeeff4; color: #8a8a8b; font-size: 0.8rem">
          <span class="mr-auto">© МИЭМ ВШЭ 2024</span>
          <!--          <b-button size="sm" @click="hide">Close</b-button>-->
          {{ version }}
        </div>
      </template>
    </b-sidebar>

    <!-- Хлебные крошки -->
    <b-row style="padding-top: 5rem; width: 100%" v-if="this.$root.global_object.current_menu != 'item-conf'">

      <b-col cols="12">
        <b-breadcrumb :items="this.$root.global_object.bread_items" size="sm"
                      style="font-size: 0.875rem; background-color: inherit;  padding: 0.4rem 0rem 0.5rem 1.6rem; margin: 0; overflow-wrap: break-word; "></b-breadcrumb>
      </b-col>

    </b-row>
    <router-view>
    </router-view>
    <!--    <b-row>-->
    <!--      <b-col cols="12">-->

    <!--      </b-col>-->
    <!--    </b-row>-->

  </div>
</template>

<script>
import RoomInfo from "@/components/RoomInfo";
import FixWebm from 'fix-webm-duration';
import PassCodeEditor from "./components/PassCodeEditor";
import PassCodes from "./components/PassCodes";
import * as Sentry from "@sentry/vue";
import md5 from "md5";

import * as ics from 'ics';


export default {
  name: 'App',
  components: {
    PassCodeEditor,
    RoomInfo,
    PassCodes
  },
  beforeMount() {
    this.$root.global_object.api_check_auth = this.api_check_auth;
    this.$root.global_object.go_link = this.go_link;
    this.$root.global_object.go_link_blank = this.go_link_blank;
    this.$root.global_object.is_auth = this.is_auth;
    this.$root.global_object.is_admin = this.is_admin;
    this.$root.global_object.showModalCreatePassCode = this.showModalCreatePassCode;
  },
  mounted() {
    document.title = "Система видео-конференций МИЭМ НИУ ВШЭ";

    this.api_check_auth();
    // Запускаем функцию при прокрутке страницы
    let m_b = this.$refs["main-menu-button-div"];
    if (m_b === undefined)
      alert("Undef");
    let check_f = this.visible_element_check;
    let setter = this.setterVisibleTopButton;
    window.addEventListener('scroll', function () {
      setter(!check_f(m_b));
    });
    //this.myUndefinedFunction();
  },
  data() {
    return {
      // Обработчик события создания нового пропуска
      eventPassCodeEditor: () => {
      },
      top_button_visible: false,
      conf_states: {
        current_role: "none",
        api: null,
        is_local_record_now: false,
        is_cloud_record_now: false,
      },

      //version: "v.1.3.1",
      version: "v." + process.env.VUE_APP_VERSION,
      mic_muted: true,
      recorder: null,
      recordingData: [],
      recorderStream: null,
      gumStream: null,
      gdmStream: null,
      is_conf_now: false,
      video_record_start_time: null,
      timer_sec: 0,
      timer_str: "",
      timer: null,
      user_name: "",
      user_is_admin: false,
      user_picture: "",
      user_avatar_checked: false,
      user_gravatar_profile: "",
      user_email: "",
      user_roles: "",
      user_shortname: "",
      user_has_picture: false,
      is_auth_flag: false,
      room_text: "",
      room_full_link: "",
      url_profile: "https://profile.miem.hse.ru/auth/realms/MIEM/account/",
      url_logout: "/api/v1/logout",
      url_login: "/api/v1/login_kc",
      nonempty_local_records: false,
      video_conference_status: false,
      status_timer: null,
      // global_object: {},
      occupant_id: '',
      event_type: 0
    }
  },
  methods: {
    checkAvatar(avatar_url, success_call = null, error_call = null) {
      //avatar_url = "http://i.stack.imgur.com/2rVXQ.png?s=64&g=1";
      //avatar_url = "https://lh6.googleusercontent.com/-WH-CWoiywPY/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmT3xjJQh2zBeQCs_ilM0kvn7evmQ/s96-c/photo.jpg";
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 3000)

      fetch(new Request(avatar_url, {
        signal: controller.signal,
        method: 'HEAD', mode: 'no-cors',
      }))
          .then(() => {
            this.user_avatar_checked = true;
            if (success_call)
              success_call();
            clearTimeout(timeoutId);
          })
          .catch(error => {
            console.log(error);
            this.user_avatar_checked = false;
            if (error_call)
              error_call();
          });
    },
    setterVisibleTopButton(value) {
      this.top_button_visible = value;
    },
    // When the user clicks on the button, scroll to the top of the document
    go_to_top() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    visible_element_check(target) {
      // Все позиции элемента
      let targetPosition = {
            top: window.scrollY + target.getBoundingClientRect().top,
            left: window.scrollX + target.getBoundingClientRect().left,
            right: window.scrollX + target.getBoundingClientRect().right,
            bottom: window.scrollY + target.getBoundingClientRect().bottom
          },
          // Получаем позиции окна
          windowPosition = {
            top: window.scrollY,
            left: window.scrollX,
            right: window.scrollX + document.documentElement.clientWidth,
            bottom: window.scrollY + document.documentElement.clientHeight
          };

      if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
          targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
          targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
          targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
        // Если элемент полностью видно, то запускаем следующий код
        return true;
      } else {
        // Если элемент не видно, то запускаем этот код
        return false;
      }
    }
    ,
    set_default_title() {
      document.title = "Система видео-конференций МИЭМ НИУ ВШЭ";
    }
    ,
    showMsgBoxTwo() {
      this.$bvModal.msgBoxConfirm('Локальная запись осуществляется средствами вашего браузера. ' +
          'После начала записи необходимо выбрать текущую вкладку или окно содержимое которого будет записано. ' +
          'После окончания записи видео файл будет автоматически сохранен на ваш локальный диск. ' +
          'Для корректной записи аудио потока в некоторых браузерах необходимо дополнительно установить галочку на пункте «Предоставить доступ к аудио».', {
        title: 'Предупреждение!',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Начать запись',
        cancelTitle: 'Отмена',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
    }
    ,
    getFilename() {
      const now = new Date();
      const timestamp = now.toISOString();
      const room = this.room_text;
      if (room && room[1] !== "")
        return `recording_${room}_${timestamp}`;
      else
        return `recording_${timestamp}`;
    }
    ,
    occupant_id_generation() {
      function chr4() {
        return Math.random().toString(16).slice(-4);
      }

      return chr4() + chr4() +
          '-' + chr4() +
          '-' + chr4() +
          '-' + chr4() +
          '-' + chr4() + chr4() + chr4();
    }
    ,
    async record_start() {
      // let gumStream, gdmStream;
      //console.log("In record start", this);
      this.recordingData = [];

      try {
        this.gumStream = await navigator.mediaDevices.getUserMedia({video: false, audio: true});
        this.gdmStream = await navigator.mediaDevices.getDisplayMedia({
          video: {displaySurface: "browser"},
          audio: {channelCount: 2}
        });
        const constraints = {
          // width: {min: 640, ideal: 1280},
          // height: {min: 480, ideal: 720},
          // width: {min: 640, max: 1280},
          // height: {min: 480, max: 720},
          frameRate: {max: 15},
          resizeMode: "crop-and-scale"
          // advanced: [
          //   {width: 1920, height: 1280},
          //   {aspectRatio: 1.333}
          // ]
        };
        const track = this.gdmStream.getVideoTracks()[0];
        track.applyConstraints(constraints)
            .then(() => {
              // Do something with the track such as using the Image Capture API.
            })
            .catch(e => {
              alert('The constraints could not be satisfied by the available devices' + e);
              // The constraints could not be satisfied by the available devices.
            });

      } catch (e) {
        console.error("capture failure", e);
        return
      }

      this.recorderStream = this.gumStream ? this.mixer(this.gumStream, this.gdmStream) : this.gdmStream;
      this.recorder = new MediaRecorder(this.recorderStream, {mimeType: 'video/webm;codecs=vp8,opus'});
      // this.recorder = new MediaRecorder(this.recorderStream, {mimeType: 'video/webm;'});
      //this.recorder = new MediaRecorder(this.recorderStream, {mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"'});
      this.recorder.ondataavailable = e => {
        //alert("data avail");
        if (e.data && e.data.size > 0) {
          //alert("data avail and push");
          this.recordingData.push(e.data);
        }
      };

      this.recorder.onStop = () => {
        console.log("In stop event");
        this.recorderStream.getTracks().forEach(track => track.stop());
        this.gumStream.getTracks().forEach(track => track.stop());
        this.gdmStream.getTracks().forEach(track => track.stop());

      };

      this.recorderStream.addEventListener('inactive', () => {
        console.log('Capture stream inactive');
        this.record_stop();
      });

      this.recorder.start(1000);
      console.log("started recording");
      this.video_record_start_time = Date.now();
      this.conf_states.is_local_record_now = true;
      this.occupant_id = this.occupant_id_generation(); //генерирование уникального id
      this.put_local_user_video_event(this.room_text, this.event_type = 1, this.occupant_id);
      this.api_get_room_status(this.room_text);
      this.record_mic_muted(this.mic_muted);
    }
    ,
    cloud_record_stop() {
      this.conf_states.api.executeCommand('stopRecording', 'file');
    }
    ,
    record_stop() {
      if (!this.conf_states.is_local_record_now)
        return;
      console.log("Stopping recording");
      this.conf_states.is_local_record_now = false;
      this.put_local_user_video_event(this.room_text, this.event_type = 0, this.occupant_id);
      this.api_get_room_status(this.room_text);
      this.recorder.requestData();
      setTimeout(() => {
        this.recorder.stop();
        this.recorderStream.getTracks().forEach(track => track.stop());
        if (this.gumStream)
          this.gumStream.getTracks().forEach(track => track.stop());
        if (this.gdmStream)
          this.gdmStream.getTracks().forEach(track => track.stop());
        this.record_save();
      }, 500);

    }
    ,
    record_mic_muted(state) {
      this.mic_muted = state;
      if (!this.conf_states.is_local_record_now)
        return;
      if (this.gumStream)
        this.gumStream.getTracks().forEach(track => track.enabled = !state);
    }
    ,
    record_save() {
      const blob = new Blob(this.recordingData, {type: 'video/webm'});
      let video_duration = Date.now() - this.video_record_start_time;
      // let self = this;
      // ysFixWebmDuration(blob, video_duration, function(fixedBlob) {
      FixWebm(blob, video_duration, (fixedBlob) => {
        const url = window.URL.createObjectURL(fixedBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // a.download = `${this.getFilename()}.webm`;
        a.download = this.getFilename() + ".webm";
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          console.log(`${a.download} save option shown`);
        }, 100);
      });

    }
    ,
    mixer(stream1, stream2) {
      const ctx = new AudioContext();
      const dest = ctx.createMediaStreamDestination();
      if (stream1)
        if (stream1.getAudioTracks().length > 0)
          ctx.createMediaStreamSource(stream1).connect(dest);

      if (stream2)
        if (stream2.getAudioTracks().length > 0)
          ctx.createMediaStreamSource(stream2).connect(dest);

      let tracks = dest.stream.getTracks();
      tracks = tracks.concat(stream1.getVideoTracks()).concat(stream2.getVideoTracks());

      return new MediaStream(tracks)

    }
    ,
    goToRoute(path) {
      if (path == "/")
        if (this.$root.global_object.current_menu == "item-conf")
          return;
      this.$router.push(path).catch(() => {
      });
    }
    ,
    go_link_blank(src) {
      window.open(src, "_blank");
    }
    ,
    go_link: function (src) {
      window.location.href = src;
    }
    ,
    go_link_logout: function (src) {
      localStorage.setItem('flag_last_login', false);
      window.location.href = src;
    }
    ,
    login() {
      if (this.room_text === "")
        this.go_link(this.url_login);
      else
        this.go_link(this.url_login + "?room_name=" + this.room_text);
    }
    ,
    cloud_record_start() {
      this.conf_states.api.executeCommand('startRecording', {
        mode: 'file'
      });
      this.conf_states.is_cloud_record_now = true;
    }
    ,
    showModalInvite() {
      this.room_full_link = window.location.origin + "/" + this.room_text;
      this.$bvModal.show("modal-invite");
      // alert(this.user_email);
      //Sentry.showReportDialog({ eventId: event.event_id,user:{email: this.user_email} });
    }
    ,
    showModalFeedback() {
      this.$refs.profileSidebar.hide();
      // alert(this.user_email);
      //this.newTest();
      let eventID = Sentry.captureMessage("User Feedback");
      Sentry.showReportDialog({
        eventId: eventID,
        user: {email: this.user_email, name: this.user_name},
        labelSubmit: "Отправить",
        title: "Обратная связь",
        subtitle: "Похоже, у нас возникли проблемы или у вас есть предложения.",
        labelComments: "Ваш комментарий"
      });
    }
    ,
    showModalCreatePassCode(callback = null) {
      if (callback != null)
        this.eventPassCodeEditor = callback;
      this.$bvModal.show("modal-create-pass-code");
    }
    ,
    showModalViewPassCodes() {
      // if (callback != null)
      //   this.eventPassCodeEditor = callback;
      this.$bvModal.show("modal-view-pass-codes");
    }
    ,
    makeToast(append = false, text, variant = null) {
      // this.toastCount++
      this.$bvToast.toast(text, {
        title: 'Уведомление',
        autoHideDelay: 3000,
        appendToast: append,
        variant: variant
      })
    }
    ,
    getSubjectForShare() {
      return "Приглашение присоединиться к встрече";
    }
    ,
    getBodyForShare() {
      return this.user_name + " приглашает Вас присоединиться к конференции.\r\n\r\n" +
          "Ссылка для подключения: \r\n" + this.room_full_link;
    }
    ,
    shareAsMailLinkTo() {
      let uri = "mailto:";
      uri += "?subject=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&body=";
      uri += encodeURIComponent(this.getBodyForShare());
      this.go_link_blank(uri);

    }
    ,
    shareAsOutlookCalendarLink() {
      let uri = "https://outlook.live.com/calendar/0/deeplink/compose";
      uri += "?subject=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&body=";
      uri += encodeURIComponent(this.getBodyForShare());
      uri += "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent";
      this.go_link_blank(uri);
    }
    ,
    shareAsHseCalendarLink() {
      let uri = "https://mail2.hse.ru/?path=/calendar/action/compose";
      uri += "&subject=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&location=";
      uri += encodeURIComponent(this.room_full_link);
      uri += "&body=";
      uri += encodeURIComponent(this.getBodyForShare());
      this.go_link_blank(uri);
    }
    ,
    shareAsYandexCalendarLink() {
      let uri = "https://calendar.yandex.ru/week/create";
      uri += "?name=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&description=";
      uri += encodeURIComponent(this.getBodyForShare());
      this.go_link_blank(uri);
    }
    ,
    shareAsICSFile() {
      let desc = this.getBodyForShare();
      let now = new Date();


      ics.createEvent({
        productId: window.location.origin,
        title: this.getSubjectForShare(),
        url: this.room_full_link,
        organizer: {name: this.user_name, email: this.user_email},
        description: desc,
        //       description: "123",
        start: [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()],
        //busyStatus: 'FREE',
        duration: {minutes: 60}
      }, (error, value) => {
        if (error) {
          console.log(error)
        } else {
          let blob = new Blob([value], {type: 'text/calendar;charset=utf-8,'});
          if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, "invite" + ".ics");
          } else {
            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
              link.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURI(value));
              link.setAttribute("download", "invite" + ".ics");
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        }
      });
    }
    ,
    shareAsGoogleCalendarLink() {
      let uri = "https://calendar.google.com/calendar/render?action=TEMPLATE";
      uri += "&text=";
      uri += encodeURIComponent(this.getSubjectForShare());
      uri += "&details=";
      uri += encodeURIComponent(this.getBodyForShare());
      this.go_link_blank(uri);
    }
    ,
    shareAsShareLinkTo() {
      if (navigator.share && navigator.canShare && navigator.canShare({url: this.room_full_link})) {
        try {
          navigator.share({
            title: this.getSubjectForShare(),
            text: this.user_name + " приглашает Вас присоединиться к конференции.",
            url: this.room_full_link
          }).then(() => {
            this.makeToast(true, 'Приглашение готово.', "success");
            console.log('Thanks for sharing!');
          }).catch((error) => {
            console.log("Sharing failed", error);
          });
        } catch (err) {
          console.log("Sharing failed", err);
          //alert(err);
          this.makeToast(true, 'Ваш браузер не поддерживает данную возможность!', "danger");
        }
      } else {
        this.makeToast(true, 'Ваш браузер не поддерживает данную возможность!', "danger");
        // fallback
      }
    }
    ,
    shareAsCopyLink() {
      let copyTextarea = this.$refs["full-link-plaintext"];
      copyTextarea.focus();
      copyTextarea.select();

      try {
        let successful = document.execCommand('copy');
        //let msg = successful ? 'successful' : 'unsuccessful';
        // alert('Copying text command was ' + msg)
        if (successful)
          this.makeToast(true, "Ссылка скопирована в буфер обмена", "success");
        else
          this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
      } catch (err) {
        this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
        console.log('Ссылка не скопирована. Что-то пошло не так.');
      }
    }
    ,
    set_auth_flag(val) {
      this.is_auth_flag = val;
    }
    ,
    setUserNameShort() {
      let array = this.user_name.split(" ", 2);
      let shortName = array[0][0].toUpperCase();
      if (array.length >= 2) {
        shortName = shortName + array[1][0].toUpperCase();
      }
      this.user_shortname = shortName;
    }
    ,
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }
    ,
    calc_timer: function () {
      //alert(this.timer_sec);
      let seconds = this.timer_sec % 60; // Получаем секунды
      let minutes = this.timer_sec / 60 % 60; // Получаем минуты
      let hour = this.timer_sec / 60 / 60 % 60; // Получаем часы
      // Создаём строку с выводом времени
      this.timer_str = `${Math.trunc(hour)}:${Math.trunc(minutes).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}:${seconds.toLocaleString('ru-RU', {minimumIntegerDigits: 2})}`;
      //alert(this.timer_str);
      this.timer_sec = this.timer_sec + 1; // Увеличиваем таймер
    }
    ,
    showAlertMsgBox(msg = null) {
      this.boxTwo = ''
      let title = "Ошибка"
      if (msg == null) {
        msg = 'Время сеанса истекло. Пожалуйста, авторизуйтесь в профиле.';
        title = "Требуется авторизация";
      }
      this.$bvModal.msgBoxOk(msg, {
        title: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: 'Закрыть',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            this.boxTwo = value
          })
          .catch(err => {
            console.log(err);
            // An error occurred
          })
    }
    ,
    api_get_room_status(room_name) {
      // if (this.is_auth()) {
      this.axios
          .get('/api/v1/get_room_status?room_name=' + room_name,
              {withCredentials: true})
          .then(response => {
            if (response.data.status == 1) {
              this.video_conference_status = true;
              if (response.data.local_records.length) {
                this.nonempty_local_records = true;
              } else {
                this.nonempty_local_records = false;
              }
            } else {
              this.video_conference_status = false;
            }
          })
          .catch((error) => {
            console.log(error);
          })
      // }
    }
    ,

    put_local_user_video_event(room_name, event_type, occupant_id) {
      this.axios
          .post('/api/v1/put_local_user_video_event', {
                room_name: room_name,
                event_type: event_type,
                occupant_id: occupant_id,
              }, {
                withCredentials: true,
                headers: {
                  'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
                }
              },
          )
          .catch((error) => {
            console.log(error)
          })
    }
    ,

    /**
     * Функция запроса текущего состояния пользователя
     * @param success_call function for call in success result
     * @param not_success_call
     */
    api_check_auth(success_call = null, not_success_call = null) {
      this.axios
          .get('/api/v1/get_user_info?' + this.getRandomInt(3000000).toString(), {
            withCredentials: true
          })
          .then(response => {
            //"suc user info");
            this.set_auth_flag(true);

            this.user_name = response.data.name;
            this.user_is_admin = response.data.admin;
            this.setUserNameShort();

            this.user_picture = response.data.picture;


            if (response.data.email) {
              this.user_email = response.data.email;
              //this.user_picture = "https://www.gravatar.com/avatar/" + md5(this.user_email.trim().toLowerCase()) + "?d=identicon";
              this.user_gravatar_profile = "https://www.gravatar.com/" + md5(this.user_email.trim().toLowerCase());
            }
            if (response.data.roles)
              this.roles = response.data.roles;

            localStorage.setItem('flag_last_login', true);
            if (this.user_picture === "")
              this.user_picture = "https://www.gravatar.com/avatar/" + md5(this.user_email.trim().toLowerCase()) + "?d=identicon";
            // this.user_has_picture = false;

            //this.user_has_picture = true;
            this.checkAvatar(this.user_picture, () => {
              this.user_has_picture = true;
              if (success_call) {
                success_call();
              }
            }, () => {
              this.user_has_picture = false;
              if (success_call) {
                success_call();
              }
            });

          })
          .catch((error) => {
            if (error.response) {
              this.set_auth_flag(false);
              // alert("Not auth ERROR")
              this.user_name = "";
              this.user_shortname = "";
              this.user_has_picture = false;
              this.user_picture = "";
              this.user_email = "";
              this.user_is_admin = false;
              this.user_avatar_checked = false;

              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response.status == 401) {
                let llogin = false;
                if (localStorage)
                  llogin = localStorage.getItem("flag_last_login") === "true";
                if (llogin) {
                  this.showAlertMsgBox();
                  if (localStorage)
                    localStorage.setItem('flag_last_login', "false");
                }
              } else
                this.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить запрос позже.");
              if (not_success_call) {
                not_success_call();
              }

            }
          });
    }
    ,

    is_auth() {
      return this.is_auth_flag;
    }
    ,
    is_admin() {
      return this.user_is_admin;
    }
    ,
    isMobile() {
      let vall = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i);
      if (vall == null) {
        return false;
      }
      return true;
    }
    ,
  }
}
</script>
<style scoped>
.item-header {
  border: 0px;
  padding: 0px !important;
}

.breadcrumb-item.active {
  /*color: #004085;*/
  overflow-wrap: break-word;
}

.list-group-item-primary {
  color: #004085;
  background-color: #eeeff4;
}

.list-group-item:last-child {
  border-radius: 0.25rem;
}

.list-group-item:first-child {
  border-radius: 0.25rem;
}

.menu-item {
  border-radius: 0.25rem;
  border: 0;
}

.menu-item-in-group-first {
  border-radius: 0.25rem 0 0 0.25rem !important;
  border: 0;
}

.menu-item-in-group-second {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  border: 0;
}

</style>
<style>
#layer1 {
  position: fixed; /* Относительное позиционирование */
  z-index: 1;
  bottom: 4rem; /* Сдвигаем текст вверх */
  right: 1rem; /* Сдвигаем текст вправо */
}

.menu-button-div {
  position: absolute;
  top: 2.7rem;
  left: 0rem;
}
.help-link-div {
  position: absolute;
  top: 2.7rem;
  right: 0rem;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*font-family: "Proxima Nova";*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}

html, body {
  height: 100%;
  background-color: #eeeff4;
}
</style>
