<template>
  <div id="main-rooms-history" style="padding:1rem; padding-top: 0rem;">

    <b-row align-h="between" class="justify-content-md-center" style="width: 100%; margin: 0px">
      <b-col class="col-md-8 col-xl-8 col-sm-12" style="width: 100%; padding: 0px">
        <b-row align-h="between" class="justify-content-md-center m-0 pt-0 pl-0 pr-0 pb-2"
               v-if=" this.l_by_room == false">
          <b-col class="col-md-12 col-xl-10 col-sm-12 col-lg-10">
            <PrivateChart/>
          </b-col>
        </b-row>
        <b-row align-h="between" class="m-0 p-0">
          <b-col md="12" xl="5" sm="12" xs="12" cols="12" class="p-1">
            <b-row class="p-0 m-0">
              <b-col cols="8" class="p-0 m-0">
                <b-form-input size="sm" v-model="illustrate_name" placeholder="Поиск по имени комнаты"
                              type="search" debounce="500" @update="update_room_filter"></b-form-input>
              </b-col>
              <b-col cols="4" class="p-0 m-0">
                <b-button-group size="sm">
                  <b-dropdown :variant="(is_set_filters()) ? 'info' : 'secondary'" right size="sm" id="dropdown-form"
                              text="Фильтры" ref="dropdown">
                    <b-dropdown-form>
                      <b-form-checkbox class="m-0 pr-1" style="" size="sm" v-model="l_not_fast" debounce="500"
                                       @change="update_room_filter"
                                       name="check-not-fast" inline>
                        Больше 1m.
                      </b-form-checkbox>
                      <b-form-checkbox class="m-0" style="" size="sm" v-model="l_only_online" debounce="500"
                                       @change="update_room_filter"
                                       name="check-online" inline>
                        Оnline
                      </b-form-checkbox>
                      <b-form-checkbox class="m-0" style="" size="sm" v-model="l_video_available" debounce="500"
                                       @change="update_room_filter"
                                       name="check-video-available" inline>
                        С видео
                      </b-form-checkbox>

                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item :disabled="!is_set_filters()" @click="break_filters">
                      <b-icon icon="x"></b-icon>
                      Сбросить все
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-button variant="info" size="sm" v-if="is_set_filters()" @click="break_filters" class="p-1">
                    <b-icon icon="x"></b-icon>
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" xl="7" sm="12" xs="12" cols="12" class="p-1">
            <b-row align-h="center" class="p-0 m-0">
              <b-col cols="6" class="p-0 m-0">
                <b-row class="align-items-baseline p-0 m-0">
                  <b-col cols="1" class="p-0 m-0">
                    <label size="sm" label-for="id_start_date"
                           style="font-size: 0.8rem; vertical-align: center"><strong>C</strong></label>
                  </b-col>
                  <b-col cols="11" class="pl-0 pr-0">
                    <b-form-input size="sm" v-model="illustrate_start_ts" placeholder="" id="id_start_date"
                                  type="date" debounce="500" @update="update_room_filter"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="6" class="p-0 m-0">
                <b-row class="align-items-baseline p-0 m-0">
                  <b-col cols="1" class="pr-0 pl-0 m-0">
                    <label size="sm" label-for="end_date"
                           style="font-size: 0.8rem; vertical-align: center"><strong>По</strong></label>
                  </b-col>
                  <b-col cols="11" class="pr-0 pl-1 m-0">
                    <b-form-input size="sm" v-model="illustrate_end_ts" placeholder="" id="end_date"
                                  type="date" debounce="500" @update="update_room_filter"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

        </b-row>

        <b-overlay :show="flag_loading" rounded="sm">
          <b-table striped hover v-bind:stacked="isMobile()" v-if="info_is_loaded" :items="history"
                   style="width: 100%; overflow-wrap: break-word;"
                   :fields="fields_history">
            <!-- A custom formatted column -->
            <template #cell(create_ts)="data">
              {{ dateFormat(new Date(Math.trunc(data.value * 1000)), "%d.%m.%Y %H:%M:%S", false) }}
            </template>
            <template #cell(room_name)="data">
              <b-row>
                <b-col>
                  <b-link style="word-wrap: break-word"
                          :to="{ name: 'room-info-ts', params: { room_name: data.value, time: data.item.create_ts }}">
                    {{
                      data.value
                    }}
                  </b-link>
                </b-col>

              </b-row>
              <!--              <a v-bind:href=" 'mailto:' + data.value ">{{ data.value }} </a>-->

            </template>
            <template #cell(duration)="data">
              {{ data.item.duration }}
              <b-badge v-if="data.item.online" variant="success" pill size="md">online</b-badge>
            </template>
            <template #cell(actions)="data">
              <b-row>
                <b-col>
                  <b-link :to="{ name: 'conf', params: { id: data.item.room_name }}">
                    <b-icon icon="box-arrow-in-right" aria-hidden="true" scale="1.25" variant="primary"
                            class="active-icon" v-b-popover.hover.right="'Подключиться'"></b-icon>
                  </b-link>
                </b-col>
                <b-col v-if="data.item.video_available">
                  <b-link
                      :to="{ name: 'room-info-ts', query: {tab: 'videos'}, params: { room_name: data.item.room_name, time: data.item.create_ts }}">

                    <b-icon icon="collection-play" v-if="data.item.video_available" aria-hidden="true" scale="1.25"
                            variant="secondary"
                            v-b-popover.hover.right="'Есть видео'"></b-icon>
                  </b-link>
                </b-col>
              </b-row>

            </template>
          </b-table>
          <p v-else>{{ data_message }}</p>
        </b-overlay>

        <b-row align-h="between">
          <b-col cols="auto">
            <b-pagination-nav
                size="sm"
                v-model="currentPage"
                :number-of-pages="number_of_pages"
                use-router
                :base-url='get_url_for_pagination()'
            ></b-pagination-nav>
          </b-col>
          <b-col cols="auto">
            Всего найдено: {{ this.all_events_count }}
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import PrivateChart from "./PrivateChart";

export default {
  props: {
    page: {
      type: String,
      default: "1"
    },
    by_room: {
      type: String,
      default: 'false'
    },
    only_online: {
      type: String,
      default: 'false'
    },
    not_fast: {
      type: String,
      default: 'false'
    }
  },
  watch: {
    only_online: function (newVal) {
      this.l_only_online = (newVal === 'true');
    },
    video_available: function (newVal) {
      this.l_video_available = (newVal === 'true');
    },
    page: function (newVal) {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      let s1 = Date.parse(this.illustrate_start_ts) / 1000;
      let s2 = Date.parse(this.illustrate_end_ts) / 1000;
      this.api_get_rooms_history(this.illustrate_name, newVal - 1, s1, s2, 10);
      this.currentPage = newVal;
    },
    by_room: function (newVal) {
      //alert("watch " + newVal);
      //this.by_room = newVal;
      this.l_by_room = (newVal === 'true');
      let s1 = Date.parse(this.illustrate_start_ts) / 1000;
      let s2 = Date.parse(this.illustrate_end_ts) / 1000;
      this.api_get_rooms_history(this.illustrate_name, this.currentPage - 1, s1, s2, 10);
    },
    not_fast: function (newVal) {
      this.l_not_fast = (newVal === 'true');
    }

  },
  name: "RoomsHistory",
  components: {
    PrivateChart
  },
  data() {
    return {
      illustrate_name: '',
      illustrate_start_ts: null,
      illustrate_end_ts: null,
      currentPage: 1,
      number_of_pages: 1,
      all_events_count: 0,
      l_only_online: false,
      l_by_room: false,
      l_video_available: false,
      l_not_fast: false,
      data_message: "",
      ts: null,
      history: [],
      messages: [],
      flag_loading: true,
      info_is_loaded: false,
      fields_history: [
        {
          key: 'actions',
          label: "",
          sortable: false
        },
        {
          key: 'room_name',
          label: "Имя комнаты",
          sortable: true,
          tdClass: "roomNameColumn"
        },
        {
          key: 'create_ts',
          label: "Время",
          sortable: true
        },
        {
          key: 'duration',
          label: "Длительность",
          sortable: false
        }
      ],
    }
  },
  mounted() {

    let page = this.$route.query.page;

    if (!page) {
      page = 1;
    }
    this.currentPage = parseInt(page);
    let room_name = this.$route.query.room_name; // для фильтров
    if (!room_name) {
      room_name = '';
    }
    //test
    let start_ts = this.$route.query.start_ts;
    if (!start_ts) {
      start_ts = null;
    } else {
      this.illustrate_start_ts = start_ts;
    }
    let end_ts = this.$route.query.end_ts;
    if (!end_ts) {
      end_ts = null;
    } else
      this.illustrate_end_ts = end_ts;
    this.illustrate_name = room_name;
    this.currentPage = parseInt(page);
    let c_window = this;

    // преобразование дат в таймстемпы
    let s1 = null;
    if (c_window.illustrate_start_ts)
      s1 = Date.parse(c_window.illustrate_start_ts) / 1000;
    let s2 = null;
    if (c_window.illustrate_end_ts)
      s2 = Date.parse(c_window.illustrate_end_ts) / 1000;
    // alert(this.only_online);
    this.l_only_online = (this.$route.query.only_online === 'true');
    this.l_video_available = (this.$route.query.video_available === 'true');
    this.l_by_room = (this.$route.query.by_room === 'true');

    this.l_not_fast = (this.$route.query.not_fast === 'true');
    //this.l_only_online = this.only_online;
    this.api_get_rooms_history(room_name, page - 1, s1, s2, 10);

  },
  methods: {
    get_url_for_pagination() {
      let url = "?";
      if (this.illustrate_name && this.illustrate_name.length > 0)
        url += "room_name=" + this.illustrate_name + "&";
      if (this.illustrate_start_ts)
        url += "start_ts=" + this.illustrate_start_ts + "&";
      if (this.illustrate_end_ts)
        url += "end_ts=" + this.illustrate_end_ts + "&";
      if (this.l_by_room == true)
        url += "by_room=" + this.l_by_room + "&";
      if (this.l_only_online == true)
        url += "only_online=" + this.l_only_online.toString() + "&";
      if (this.l_not_fast == true)
        url += "not_fast=" + this.l_not_fast.toString() + "&";
      if (this.l_video_available == true)
        url += "video_available=" + this.l_video_available.toString() + "&";

      url += 'page=';
      return url;
    },
    is_set_filters() {
      return this.l_only_online || this.l_not_fast || this.l_video_available;
    },
    update_room_filter() {
      let c_window = this;

      // преобразование дат в таймстемпы
      let s1 = Date.parse(c_window.illustrate_start_ts) / 1000;
      let s2 = Date.parse(c_window.illustrate_end_ts) / 1000;
      this.api_get_rooms_history(this.illustrate_name, c_window.currentPage - 1, s1, s2, 10);
      this.$router.push({
        name: this.$route.name,
        query: {
          room_name: this.illustrate_name,
          start_ts: this.illustrate_start_ts,
          end_ts: this.illustrate_end_ts,
          only_online: this.l_only_online.toString(),
          by_room: this.l_by_room.toString(),
          not_fast: this.l_not_fast.toString(),
          video_available: this.l_video_available.toString()
        }
      });

    },
    break_filters() {
      this.l_video_available = false;
      this.l_not_fast = false;
      this.l_only_online = false;
      this.update_room_filter();
    },

    // get_end_ts_string(ts) {
    //   // alert(ts.value)
    //   if (ts.value === "" || ts.value == null) {
    //     // alert("NULL");
    //     return ""
    //   }
    //
    //   return this.dateFormat(new Date(Math.trunc(ts.value * 1000)), "%d.%m.%Y %H:%M:%S", false)
    // },
    api_get_rooms_history(room_name, page, start_ts = null, end_ts = null, limit = 10) {
      this.flag_loading = true;
      this.info_is_loaded = false;
      let ext_admin_query = '';

      if (this.l_by_room)
        ext_admin_query += "&by_room=" + this.l_by_room;
      if (this.l_only_online)
        ext_admin_query += "&online=" + this.l_only_online;
      if (this.l_video_available)
        ext_admin_query += "&video_available=" + this.l_video_available;
      if (this.l_not_fast)
        ext_admin_query += "&not_fast=" + this.l_not_fast;


      this.axios
          .get('/api/v1/get_rooms_history?room_name=' + room_name + '&start_ts=' + start_ts + '&end_ts=' + end_ts + '&page=' + page + '&limit=' + limit + ext_admin_query, {
            withCredentials: true
          })
          .then(response => {
            //"suc room info");
            //alert(JSON.stringify(response.data));
            // console.log(response.data)
            if (response.data) {

              this.info_is_loaded = true;
              this.flag_loading = false;

              this.history = response.data.history;
              for (let item of this.history) {
                [item.duration, item.online] = this.$root.global_object.methods.get_duration_string(item.create_ts, item.end_ts);
              }
              //alert(response.data.all_events_count);
              this.all_events_count = response.data.all_events_count;
              this.number_of_pages = Math.ceil((response.data.all_events_count + 1) / limit);

            } else {
              this.info_is_loaded = false;
              this.data_message = "Данные отсутствуют";
              this.flag_loading = false;
            }
          })
          .catch((error) => {
            this.info_is_loaded = false;
            this.data_message = "Данные отсутствуют";
            this.flag_loading = false;
            this.all_events_count = 0;
            if (error.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
          });
      //this.flag_loading = false;
    },
    isMobile() {
      let vall = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i);
      if (vall == null) {
        return false;
      }
      return true;
    },
    dateFormat: function (date, fstr, utc) {
      if (date === null)
        return ""
      utc = utc ? 'getUTC' : 'get';
      return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
          case '%Y':
            return date[utc + 'FullYear'](); // no leading zeros required
          case '%m':
            m = 1 + date[utc + 'Month']();
            break;
          case '%d':
            m = date[utc + 'Date']();
            break;
          case '%H':
            m = date[utc + 'Hours']();
            break;
          case '%M':
            m = date[utc + 'Minutes']();
            break;
          case '%S':
            m = date[utc + 'Seconds']();
            break;
          default:
            return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
      });
    },
  }
}
</script>
<style>

.roomNameColumn {
  max-width: 50rem;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: clip ellipsis;
}

.page-item.active .page-link {
  background-color: #004085 !important;
  border-color: #004085;
}

.page-link {
  background-color: #eeeff4 !important;
  /*color: #004085;*/
}

.page-link:hover {
  background-color: #b8daff !important;
}
</style>
<style scoped>

</style>