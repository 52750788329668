<script>

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Tooltip} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.plugins.register(Tooltip);
ChartJS.plugins.register(ChartDataLabels);
//const { reactiveProp } = mixins
export default {
  extends: Bar,
  //mixins: [mixins.reactiveData],
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null,
    },
    id: {
      type: Number,
      default: -1
    }
  },

  watch:
      {
        chartdata: function() {
          //this.$data._chart.update()
          //this._chart.destroy();
          this.renderChart(this.chartdata, this.options, this.id);
          //alert("render" + this.chartdata.labels);
        }
      },
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(this.chartdata, this.options, this.id)
    //this.renderChart(this.chartData, this.options)
  },
  methods: {

  }
}

</script>
