<template>
  <div>
    <b-row align-h="between" class="m-0 p-0">
      <b-col cols="12" class="p-0">
        <b-row class="pt-0 pr-0 pl-0 pb-1 m-0" align-v="center">
          <b-col cols="2" class="p-0 m-0 text-right">
            <b-button pill variant="outline-secondary" size="sm" @click="decrease" :disabled="disabled_dec">
              <b-icon icon="caret-left"></b-icon>
            </b-button>
          </b-col>
          <b-col cols="8">
            <BarChart
                :id=1
                :chartdata="chartUsers"
                :options="chartOptionsUsers"
                style="width: 100%; height: 8rem; background-color: rgba(255, 255, 255, 0.63);
                border-radius: 5px; box-shadow: 0 0px 0px rgba(0,0,0,0.25), 0px 0px 10px rgba(0,0,0,0.22);"
            />

          </b-col>
          <b-col cols="2" class="p-0 m-0 text-left">
            <b-button pill variant="outline-secondary" size="sm" @click="increase" :disabled="disabled_inc">
              <b-icon icon="caret-right"></b-icon>
            </b-button>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import BarChart from "./BarChart";


export default {
  name: "PrivateChart",
  components: {
    BarChart
  },
  data() {
    return {
      disabled_inc: true,
      disabled_dec: true,
      array_resp_labels: [],
      array_resp_values: [],
      array_aver_values: [],
      array_win_labels: [],
      array_win_values: [],
      array_win_aver_values: [],
      win_left: 0,
      win_right: 0,
      win_size: 4,
      chartUsers: {
        labels: [],
        datasets: [
          {
            categoryPercentage: 1.0,
            barThickness: 80,
            maxBarThickness: 100,
            minBarLength: 12,
            barPercentage: 1.0,
            label: 'Минут за месяц',
            backgroundColor: '#0050cf',
            data: [],

          }
        ]
      },

      chartOptionsUsers: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            top: 30,
            left: 0,
            right: 0
          }
        },
        tooltips: {
          intersect: true,
          mode: 'x',
          callbacks: {
            /*footer: function () {
              return "zxccc";
            },*/
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              let d = Math.trunc(tooltipItem.yLabel / 1440)
              let h = Math.trunc((tooltipItem.yLabel / 60) % 24);
              let m = tooltipItem.yLabel % 60;
              if (label) {
                label += ': ';
              }
              if (d > 0) {
                label += d + "д. "
              }
              if (h > 0) {
                label += h + "ч. "
              }
              if (m > 0) {
                label += m + "м."
              }
              //alert(label);
              return label;
            }
          }

        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'top',
            color: '#004085',
            // and if you need to format how the value is displayed...
            formatter: function (value, context) {
              if (context.datasetIndex>0)
                return "";
              let d = Math.trunc(value / 1440)
              let h = Math.trunc((value / 60) % 24);
              let m = value % 60;
              let label = "";
              let label_parts = 0;
              if (d > 0) {
                label += d + "д. "
                label_parts ++;
              }
              if (h > 0) {
                label += h + "ч. "
                label_parts ++;
              }
              if (m > 0 && label_parts < 2) {
                label += m + "м."
              }
              return label;
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              offset: true,
              offsetGridLines: false,
              ticks: {
                autoSkip: true,
                //maxTicksLimit: 12
              },
              type: 'time',
              time: {
                unit: 'month',
                tooltipFormat: "MMM YYYY",
                displayFormats: {
                  hour: 'YYYY MMM D, H[h]'
                },
              }
            }
          ],
          yAxes: [
            {
              title: {
                display: true,
                text: 'Value'
              },
              gridLines: {
                color: "transparent",
                display: true,
                lineWidth: 0,
                zeroLineWidth: 1

              },
              ticks: {
                display: false,
                autoSkip: true,
                beginAtZero: true,
                callback: this.ticks_callback,
              }
            }
          ]
        }
      },
    }
  },
  mounted() {
    this.update_data();
  },
  methods: {
    ticks_callback(value) {
      //alert(value);
      let label = ""
      let d = Math.trunc(value / 1440)
      let h = Math.trunc((value / 60) % 24);
      let m = value % 60;
      if (d > 0) {
        label += d + "д. "
      }
      if (h > 0) {
        label += h + "ч. "
      }
      if (m > 0) {
        label += m + "м."
      }
      return label;
    },
    update_data() {
      this.api_monitoring_get_user_stats()
    },
    update_stats_chart_data() {
      this.array_win_values = this.array_resp_values.slice(this.win_left, this.win_right);
      this.array_win_aver_values = this.array_aver_values.slice(this.win_left, this.win_right);
      this.array_win_labels = this.array_resp_labels.slice(this.win_left, this.win_right);
      this.chartUsers = {
        labels: this.array_win_labels,
        datasets: [
          {
            minBarLength: 3,
            barPercentage: 0.6,
            label: 'Провели в общении',
            //backgroundColor: '#0050cf',
            borderColor: '#0050cf',
            backgroundColor: 'rgba(0, 80, 207, 0.63)',
            data: this.array_win_values
          }

        ]
      };
      //if (!this.$root.global_object.methods.isMobile()) {
        this.chartUsers.datasets[1] = {
          minBarLength: 3,
          barPercentage: 0.6,
          label: 'Среднее время',
          borderColor: 'rgba(207, 80, 0, 0.63)',
          backgroundColor: 'rgba(207, 80, 0, 0.63)',
          data: this.array_win_aver_values,
          type: 'line',
          fill: false,
        }
     // }
    },
    api_monitoring_get_user_stats() {
      this.axios
          .get('/api/v1/get_user_stats?start_ts=' + new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 1) / 1000 + '&end_ts=' + Date.now() / 1000, {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.array_resp_labels = response.data["label"];
              for (let i = 0; i < this.array_resp_labels.length; i++) {
                this.array_resp_labels[i] = this.array_resp_labels[i] * 1000;
              }

              this.array_resp_values = response.data["value"];
              let aver_summ = 0;
              for (let i = 0; i < this.array_resp_values.length; i++) {

                this.array_resp_values[i] = Math.trunc(this.array_resp_values[i] / 60);
                aver_summ += this.array_resp_values[i];
                this.array_aver_values[i] = Math.trunc(aver_summ / (i + 1));
              }
              let size = this.win_size;
              if (this.array_resp_values.length < this.win_size)
                size = this.array_resp_values.length;

              this.win_left = this.array_resp_values.length - size;
              this.win_right = this.array_resp_values.length;
              this.disabled_inc = (this.win_right == this.array_resp_values.length);
              this.disabled_dec = (this.win_left == 0);
              this.update_stats_chart_data();
            }
          })
          .catch((error) => {
            if (error.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
          });
    },

    increase() {
      this.win_left++;
      this.win_right++;
      this.disabled_inc = (this.win_right == this.array_resp_values.length);
      this.disabled_dec = (this.win_left == 0);
      this.update_stats_chart_data();
    },
    decrease() {
      this.win_left--;
      this.win_right--;
      this.disabled_inc = (this.win_right == this.array_resp_values.length);
      this.disabled_dec = (this.win_left == 0);
      this.update_stats_chart_data();

    },

  }
}
</script>

<style scoped>

</style>
